var $window, $document, $body, globalXHRStatus, yModalConfig;

var conf = {
    'fancyStatus': false,
    'nodeBody': $('#body'),
    'fancyDefault': {
        wrapCSS: 'fb-fancy-default',
        padding: 0,
        margin: 0,
        autoScale: false,
        fitToView: false,
        openSpeed: 300,
        closeSpeed: 300,
        nextSpeed: 300,
        prevSpeed: 300,
        closeBtn: false,
        toolbar: false,
        afterShow: function () {
            conf.nodeBody.addClass('body-state-fancy');

            // this modal parent
            var $thisFancy = $('.fancybox-inner');
            moduleApp.executeModules($thisFancy);

        },
        beforeClose: function () {
            conf.nodeBody.removeClass('body-state-fancy');
        },
        afterClose: function () {
            conf.fancyStatus = false;
        }
    }
};

var moduleApp = {
    'init': function () {
        this.initGlobals();
        this.pageLoader();
        this.pagePreLoader();
        this.initGlobalsEvents();
        this.startupMessage();
        this.executeModules();
        this.confirmLocationPopup();
        this.loaderImages();
        this.scrollMagicInit();
        this.buttonFill();
        this.setHeightIntroStart();
        this.setHeightIntroEnd();
        // this.setHeightIntroCenter();
        this.setHeightBackgroundIntroMobile();
        this.openJobModal();
        this.pageDescInnerCards();
        this.pageCardsWorks();
        this.anchorScroll();
        this.headerFixed();
        this.headerSetHeightDropdown();
        this.recaptchaResponsive();
        this.dropdownClickMobile();
        this.citySelectReloadPage();
        this.citySelectMobile();
        this.setHeightMainSection();
        this.detectedChildMenuActive();
        // this.setHeightCardsGames();
        this.dropdownJobsPositions();
        this.expandText();
        this.setWidthNameCity();
        // this.stickyHeaderInit();
        this.initDatePicker();
        this.paginationHeight();
        this.setHeightCasesEvent();
        this.lazyLoadVideosAfterLoadPage();
        this.lazyLoadImagesAfterLoadPage();
        this.lazyLoadPictureImagesAfterLoadPage();
        this.lazyLoadPosterVideoAfterLoadPage();
        this.lazyLoadImagesBackgroundStyle();
    },
    'filter': function () {
        var selects = $('.page-catalog__filter__item select');
        selects.select2({
            minimumResultsForSearch: Infinity,
        });

    },
    'stickyHeaderInit': function () {
        $(document).ready(function () {
            new Sticky('.is-header');
        });
    },
    'getRandom': function (min, max) {
        return Math.round(min - 0.5 + Math.random() * (max - min + 1));
    },
    'initGlobals': function () {
        $window = $(window);
        $document = $(document);
        $html = $('html');
        $body = $('#body');
        globalXHRStatus = false;
        $window.on('load', function () {
            $window.trigger('resize');
        });
        if ($(window.location.hash).length) {
            $(window).scrollTop($(window.location.hash).offset().top - 122);
        }
        var lazyLoadInstance = new LazyLoad({});
    },
    'initGlobalsEvents': function () {

        var methods = {
            'init': function () {
                methods.getInitialEvents('click');
                methods.getInitialEvents('change');
            },
            'getInitialEvents': function (thisEvent) {
                $document.on(thisEvent, '[data-g' + thisEvent + ']', function (e) {
                    e.preventDefault();
                    var $this = $(this);
                    var thisAction = $this.attr('data-g' + thisEvent);
                    var thisValue = $this.val() || $this.data().value;
                    if (actions[thisAction]) {
                        actions[thisAction]($this, thisValue);
                    } else {
                        console.log('Event: ' + thisEvent + ', Action:"' + thisAction + '" - not defined');
                    }
                });
            },
            'getTarget': function (thisTarget) {
                return $('[data-target="' + thisTarget + '"]');
            }
        };

        var actions = {

            'm-menu': function () {
                var $menuBtn = $('.js-hamburger');
                var $menu = $('.js-is-mobile');
                $menuBtn.toggleClass('is-active');
                $menu.toggleClass('is-active');
            },
            'closeFancy': function () {
                $.fancybox.close();
            },

            'openVideo': function ($this, thisValue) {
                var link = $this.data('video-link');
                $.fancybox.open({
                    content: '<iframe width="560" height="315" src="' + link + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
                });
            },

            'share-page': function ($this, thisValue) {
                var shareService = thisValue;
                var shareData = {
                    'link': $('[property="og:url"]').attr('content'),
                    'title': encodeURI($('[property="og:title"]').attr('content')),
                    'description': encodeURI($('[property="og:description"]').attr('content')),
                    'image': $('[property="og:image"]').attr('content')
                };

                var windowLink = 'http://share.yandex.ru/go.xml?service=' + shareService;
                var fbWindowLink = 'http://www.facebook.com/sharer/sharer.php?';
                windowLink += '&title=' + shareData.title;

                if (shareService == 'twitter') {
                    windowLink += ' ' + shareData.description;
                    windowLink += '&url=' + shareData.link;
                    windowLink += '&link=' + shareData.link;
                } else if (shareService == 'facebook') {
                    fbWindowLink += 'u=' + shareData.link;
                } else {
                    windowLink += '&url=' + shareData.link;
                    windowLink += '&link=' + shareData.link;
                    windowLink += '&description=' + shareData.description;
                    windowLink += '&image=' + shareData.image;
                }
                if (shareService == 'facebook') {
                    window.open(fbWindowLink, '', 'toolbar=0,status=0,width=625,height=435');
                } else {
                    window.open(windowLink, '', 'toolbar=0,status=0,width=625,height=435');
                }
            },
            'print-page': function () {
                window.print();
            },
            'scroll-to-anchor': function ($this, parametr) {

                var screensize = $(window).width();

                if(screensize > 1024) {
                    if($this[0].dataset.offsetAnchorDesktop) {
                        var top_margin = $this[0].dataset.offsetAnchorDesktop;
                    }
                    else {
                        var top_margin = 85;
                    }
                } else {
                    if($this[0].dataset.offsetAnchorMobile) {
                        var top_margin = $this[0].dataset.offsetAnchorMobile;
                    } else {
                        var top_margin = 109;
                    }
                }

                if($this.data('top-margin')) {
                    var top_margin = top_margin + $this.data('top-margin');
                }

                var target = $this.attr('href');
                if (target.length) {
                    $('html, body').stop().animate({
                        scrollTop: $(target).offset().top - top_margin
                    }, 700);
                }
            },
            'scroll-top': function () {
                $('html,body').animate({
                    scrollTop: 0
                }, 700);
            },
            'toggle-lk-menu': function ($this) {
                var $menu = $('#lkMenu');
                var $close = $menu.find('.js_menuClose');
                $menu.addClass('is-active');

                $document.mouseup(function (e) {
                    if (!$menu.is(e.target) && $menu.has(e.target).length === 0) {
                        $menu.removeClass('is-active');
                    }
                });
                $close.on('click', function () {
                    $menu.removeClass('is-active');
                })
            },
            'toggleMobileMenu': function ($this) {
                $html.toggleClass('is-show-mobile-menu');
            }
        };

        methods.init();
    },
    'executeModules': function () {
        $('[data-is]').each(function (i, thisModule) {
            var $thisModule = $(thisModule);
            var thisModuleName = $thisModule.attr('data-is');
            if (moduleApp[thisModuleName]) {
                console.log('Auto start: `' + thisModuleName + '`.');
                moduleApp[thisModuleName]($thisModule);
            } else {
                console.log('Module: `' + thisModuleName + '` is not defined.');
            }
        });
    },
    'animateSettings': function () {

        var widthDoc = $(document).width();

        if(animateControl() == true && widthDoc >= 1024) {

            //анимация птиц гиф на втором блоке
            var conditionBirdGif = false;

            $(window).scroll(function() {
                if(conditionBirdGif == false) {
                    if($('.desc__wrapper .desc__inner').hasClass('animated')) {
                        conditionBirdGif = true;
                    }
                } else {

                    //анимацию убираем потому что пропал блок
                    window.startAnimateBirdGif = false;

                    if(window.startAnimateBirdGif != false) {

                        var blockGifs = $('.desc__wrapper .desc__inner .description__files .files__wrapper:last-child');

                        var delayString = blockGifs.attr('data-wow-delay');
                        var durationString = blockGifs.attr('data-wow-duration');

                        var delayNumber = delayString.replace('s', '');
                        var delayNumber = delayNumber.replace('.', '');
                        if(delayNumber.length == 1) {
                            delayNumber = Number(delayNumber + '000');
                        } else if(delayNumber.length == 2) {
                            delayNumber = Number(delayNumber + '00');
                        } else if(delayNumber.length == 3) {
                            delayNumber = Number(delayNumber + '0');
                        } else {
                            delayNumber = Number(0);
                        }

                        var durationNumber = durationString.replace('s', '');
                        var durationNumber = durationNumber.replace('.', '');
                        if(durationNumber.length == 1) {
                            durationNumber = Number(durationNumber + '000');
                        } else if(durationNumber.length == 2) {
                            durationNumber = Number(durationNumber + '00');
                        } else if(durationNumber.length == 3) {
                            durationNumber = Number(durationNumber + '0');
                        } else {
                            durationNumber = Number(0);
                        }

                        var delayAnimate = delayNumber + durationNumber;

                        window.startAnimateBirdGif = false;

                        setTimeout( function () {
                            $('.desc__wrapper .desc__inner .description__files .files__wrapper img.bird').addClass('d-none');
                            $('.desc__wrapper .desc__inner .description__files .files__wrapper img.default').removeClass('d-none');
                        }, delayAnimate)
                    }
                }
            });

            //плавный скролл главной
            // $.scrollify({
            //     section : ".section-scroll",
            //     sectionName : false,
            //     interstitialSection : "",
            //     easing: "easeOutExpo",
            //     scrollSpeed: 1500,
            //     offset : -80,
            //     scrollbars: true,
            //     setHeights: false,
            //     overflowScroll: true,
            //     updateHash: false,
            //     touchScroll: false,
            //     before:function(i) {
            //
            //         window.conditionScrollPageUser = false;
            //
            //         if(i == 5) {
            //             //$('.whoIs__block').addClass('animate-opacity');
            //             // $('.reality-block .reality__wrapper').addClass('animate-fullWidth');
            //         }
            //         if(i == 7) {
            //             $('.wrapper-testimonials__index-page .testimonials__wrapper .video-js').addClass('animate-fullWidth');
            //         }
            //     },
            //     after:function(i) {
            //
            //         $.scrollify.update();
            //
            //         setTimeout(function () {
            //             window.conditionScrollPageUser = true;
            //         }, 50)
            //
            //         if(i == 12) {
            //             $.scrollify.destroy();
            //             removeWowClasses();
            //             window.closeAnimation = true;
            //         }
            //     },
            //     afterResize:function() {},
            //     afterRender:function() {}
            // });

            //уничтожаем скролл и анимацию если мы достигли футера
            $(window).scroll(function () {
                var footer = $('.footer');
                if(footer.length) {
                    if (elem_in_visible_area('.footer .bottom-side__row')) {
                        setTimeout(function () {
                            // $.scrollify.destroy();
                            removeWowClasses();
                        }, 1100)
                    }
                }
            })

            // window.conditionPlayVideo = false;

            //запускаем видео в блоке Reality когда мы достигли его
            // $(window).scroll(function () {
            //     var block = $('.reality-block');
            //     if(block.length && window.conditionPlayVideo == false) {
            //         if (elem_in_visible_area(block)) {
            //             var videoReality = $('#video__article')[0];
            //             if(videoReality) {
            //                 videoReality.play();
            //             }
            //             $('.reality-block .reality__wrapper').addClass('animate-fullWidth');
            //             window.conditionPlayVideo = true;
            //         }
            //     }
            // })

            //анимация появления маркеров и кластеров на карте
            window.conditionAnimateMarkersMap = false;

            $markers = $('.js-map-map img[src="/img/pin.svg"]');
            $clusters = $('.js-map-map .cluster0');

            for (var i = 0; i < $markers.length; i++) {
                $markers[i].classList.add('animate-js__custom');
            }

            for (var i = 0; i < $clusters.length; i++) {
                $clusters[i].classList.add('animate-js__custom');
            }

            $(window).scroll(function () {
                if ($('.js-map-map') && elem_in_visible_area('.js-map-map')) {

                    if(window.conditionAnimateMarkersMap == false) {

                        $markers = $('.js-map-map img[src="/img/pin.svg"]');
                        $clusters = $('.js-map-map .cluster0');

                        $k = 500;

                        for (var i = 0; i < $markers.length; i++) {
                            $k = $k + 500;
                            $markers[i].classList.add('transformScaleUp');
                            $markers[i].classList.add('animDelay-' + $k);
                        }

                        for (var i = 0; i < $clusters.length; i++) {
                            $k = $k + 500;
                            $clusters[i].classList.add('transformScaleUp');
                            $clusters[i].classList.add('animDelay-' + $k);
                        }

                        window.conditionAnimateMarkersMap = true;
                    }
                }
            });

            var blockTitleMap = $('.map-locations .locations__wrapper .s-title');
            var blockTitlePos = blockTitleMap.offset();
            var blockLocationsMap = $('.map-locations .locations__wrapper .l-bottom');
            var blockLocationsPos = blockLocationsMap.offset();

            //скролл карты

            // window.conditionAnchorLocationsMap = false;
            //
            // $(window).scroll(function () {
            //     var currentScroll = $(document).scrollTop();
            //     if((currentScroll > (blockTitlePos.top - 100)) && (currentScroll < (blockLocationsPos.top - 210))) {
            //         $(blockTitleMap).removeClass('bottom-absolute');
            //         if(!$(blockTitleMap).hasClass('fixed')) {
            //             $(blockTitleMap).addClass('fixed');
            //         }
            //     }
            //     else if(currentScroll > (blockLocationsPos.top - 210)) {
            //         $(blockTitleMap).addClass('bottom-absolute');
            //     }
            //     else {
            //         if($(blockTitleMap).hasClass('fixed')) {
            //             $(blockTitleMap).removeClass('fixed');
            //             $(blockTitleMap).removeClass('bottom-absolute');
            //         }
            //     }
            // })

            //press + testimonials видео

            // var blockPress = $('.press-section').offset();
            //
            // $(window).scroll(function () {
            //     var currentScroll = $(document).scrollTop();
            //
            //     if(currentScroll >= (blockPress.top - 700) && window.conditionHiddenScrollLocationsMap != true) {
            //
            //         // $('.map-locations .locations__wrapper .l-bottom').addClass('before-el-active');
            //
            //         window.conditionHiddenScrollLocationsMap = true;
            //
            //         // setTimeout(function () {
            //         //     $('.map-locations .locations__wrapper .l-bottom').removeClass('before-el');
            //         //     $('.map-locations .locations__wrapper .l-bottom').removeClass('before-el-active');
            //         // }, 1500)
            //
            //         var video = $('.wrapper-testimonials__index-page video')[0];
            //         if(video) {
            //             video.play()
            //         }
            //     }
            // })
            //
            // //testimonials анимация
            //
            // $(window).scroll(function () {
            //     var currentScroll = $(document).scrollTop();
            //     var blockPosTestimonialsVideo = $('.wrapper-testimonials__index-page .testimonials__wrapper .video-js').offset();
            //     if(currentScroll >= (blockPosTestimonialsVideo.top - 300)) {
            //         if(!$('.wrapper-testimonials__index-page .testimonials__wrapper .video-js').hasClass('animate-fullWidth')) {
            //             $('.wrapper-testimonials__index-page .testimonials__wrapper .video-js').addClass('animate-fullWidth');
            //         }
            //     }
            // })

            //динамический контент update scrollify
            // $('.faq-section .ia-item').click(function (e) {
            //     $.scrollify.update();
            // });

        }

        window.addEventListener("keydown", function(e) {
            if(window.conditionScrollPageUser == false) {
                if([32, 33, 34, 35, 36, 37, 38, 39, 40].indexOf(e.keyCode) > -1) {
                    e.preventDefault();
                }
            }
        });

        window.addEventListener("wheel", function (e) {
            if(window.conditionScrollPageUser == false) {
                e.preventDefault()
            }
        }, { passive:false })

        function removeWowClasses() {
            $('body').addClass('removeWowJs');
        }

        function elem_in_visible_area(selector) {
            var elem_p = $(selector),
                elem_p_height = elem_p.height(),
                offset_top_el = elem_p.offset().top,
                offset_bottom_el = offset_top_el + elem_p.height(),
                scrolled = $(window).scrollTop(),
                scrolled_bottom = scrolled + $(window).height();
            if (scrolled_bottom > offset_top_el && offset_bottom_el > scrolled) {
                return true;
            }
            return false;
        }

        function animateControl() {
            if($('body').hasClass('animate')) {
                return true;
            } else {
                return false;
            }
        }
    },
    'pageLoader': function () {
        $body.addClass('jsfx-loaded');
        setTimeout(function () {
            moduleApp.inViewLoader();
        }, 500);
    },
    'pagePreLoader': function () {

        //preload

        var widthDoc = $(document).width();

        $(window).scrollTop(0);

        var loc = window.location.pathname;
        var dir = loc.substring(0, loc.lastIndexOf('/'));

        if(widthDoc < 1024 && dir != '/library') {
            $('.loader').css('display', 'none');
        } else if(widthDoc > 1024 && dir == '/library') {
            setTimeout(function () {
                $(".loader").fadeOut("slow");
            }, 350);
        }
        else if(getCookie('animate-preloader--hidden') == 'true' && widthDoc >= 1024) {

            hiddenScrollPage();

            setTimeout(function () {
                moduleApp.animateSettings();
                startAnimateWow();
            }, 300)

            setTimeout(function () {
                $(".loader").fadeOut("slow");
            }, 350);

            setTimeout(function () {
                enableScrollPage();
            }, 1700)

            setTimeout(function () {
                $('.is-header .is-header__logo:not(:nth-child(2))').removeClass('animated');
            }, 1500);

            var video = document.getElementById("intro__video");

            var checkBot = document.getElementById("check__bot-speed");

            var intervalCheckReadyStateVideo = setInterval(function () {

                if(video) {

                    var lazyLoadSrc = $(video).find('source').attr('data-src');

                    if(lazyLoadSrc) {

                        $(video).find('source').removeAttr('data-src');
                        $(video).find('source').attr('src', lazyLoadSrc);
                        video.load();

                        clearInterval(intervalCheckReadyStateVideo);

                    } else {
                        clearInterval(intervalCheckReadyStateVideo);
                    }

                } else {
                    clearInterval(intervalCheckReadyStateVideo);
                }

            }, 10)

        }
        else {
            if(animateControl() == true && widthDoc >= 1024) {

                //записываем в куку чтобы не воспроизводить анимацию прелоадера при второй загрузке
                setCookie('animate-preloader--hidden', true, 1);

                hiddenScrollPage();

                var video = document.getElementById("intro__video");

                var checkBot = document.getElementById("check__bot-speed");

                //скрываем лого в шапке
                setTimeout(function () {
                    if (checkFixedHeader() == false && checkWhiteHeader() == false) {
                        $('.is-header .is-header__logo').css({'opacity': '0'});
                    }
                }, 10);

                var count = 0;

                var refreshAnimate = setInterval(function () {
                    $('.loader .loader__preview .logo__img .preview__logo svg .pink').css({'fill': '#FC0073'});

                    //смена цветов прелоадера
                    setTimeout(function () {
                        if(count >= 3) {
                            $('.loader .loader__preview .logo__img .preview__logo svg .pink').css({'fill': '#FC0073'});
                        } else {
                            $('.loader .loader__preview .logo__img .preview__logo svg .pink').css({'fill': 'none'});
                        }
                    }, 100)

                    count++;

                    if(count >= 3) {

                        //выключаем функцию смену цветов прелоадера
                        clearInterval(refreshAnimate);

                        //меняем цвета на стандартные и делаем анимацию второго блока
                        $('.loader .loader__preview .logo__img .preview__logo svg .pink').css({'fill': '#FC0073'});
                        $('.loader .loader__preview .logo__img .preview__logo_2').css({'transform':'translateY(-5px)', 'opacity':'1'});

                        //анимация поялвения слов в лого
                        setTimeout(function () {
                            $('.loader .loader__preview .logo__img').css({'margin-top':'0px','transform':'translate(-120px, 0px)'});
                            $('.loader .loader__preview .logo__word').css({'transform':'translateX(130px)', 'opacity':'1'});
                        }, 300)

                        if(checkFixedHeader() == true || checkWhiteHeader() == true) {
                            setTimeout( function () {
                                $(".loader .preview__logo").fadeOut("slow");
                                setTimeout( function () {
                                    $(".loader").fadeOut("slow");
                                    enableScrollPage();
                                    moduleApp.animateSettings();
                                }, 400)
                            }, 400)
                        }
                        else if(animateControl() == true) {

                            $(window).scroll(function () {

                                if($('.is-header').hasClass('is-header__fixed') || $('.is-header').hasClass('header--white')) {
                                    $('.loader .pink-logo').addClass('d-none');
                                    $('.loader .purple-logo').removeClass('d-none');
                                } else {
                                    $('.loader .purple-logo').addClass('d-none');
                                    $('.loader .pink-logo').removeClass('d-none');
                                }

                                //запускаем анимацию

                                var scrollTop = $(window).scrollTop();
                                var posLogo = $('.is-header .is-header__logo:visible').offset();
                                var posLogoHeight = $('.is-header .is-header__logo:visible').height();
                                var loaderLogoWrapper = $('.loader .loader__preview .logo');
                                var loaderLogoOffset = loaderLogoWrapper.offset();
                                var headerWrapperWidth = $('.is-header .l-wrapper').width();
                                var headerWrapperHeight = $('.is-header .l-wrapper').height();
                                var docWidth =  $(document).width();
                                var docHeight = $(window).height();

                                //считаем левую позицию
                                var leftPos = -((headerWrapperWidth / 2) - (loaderLogoWrapper.width() / 2) + 61);

                                //считаем верхнюю позицию
                                if($('.is-header').hasClass('is-header__fixed') || $('.is-header').hasClass('header--white')) {
                                    var topPos = (docHeight / 2) - (docHeight-headerWrapperHeight) - 2;
                                } else {
                                    var topPos = (docHeight / 2) - (docHeight-headerWrapperHeight) + 16;
                                }

                                loaderLogoWrapper.css({'transform':''});
                                loaderLogoWrapper.css({'transform':'translate'+'('+leftPos+'px'+','+topPos+'px'+')'});

                            })

                            setTimeout(function () {

                                $('.loader').css({'background':'none', 'pointer-events':'none'});

                                $('.loader .loader__preview .logo__img').addClass('small__preview');
                                $('.loader .loader__preview .logo__word').addClass('small__preview');

                                $('.loader .loader__preview .logo__img').css({'margin-top':'0px','transform':'translate(-22.5px, 0px) scale(0.15)'});
                                $('.loader .loader__preview .logo__img .preview__logo_1').css({'transform':'scale(1.1)'});
                                $('.loader .loader__preview .logo__img .preview__logo_2').css({'transform':'translateY(5px)'});

                                setTimeout(function () {
                                    $('.loader .loader__preview .logo__img .preview__logo_2').css({'transform':'translateY(-4px) scale(0.8)'});
                                }, 500)

                                $('.loader .loader__preview .logo__word').css({'margin-top':'0px','transform':'translate(22.5px, 0px) scale(0.22)'});

                                moduleApp.animateSettings();
                                startAnimateWow();

                            }, 700)

                            //перемещение преалодера лого в лого шапки

                            setTimeout(function () {

                                enableScrollPage();

                                //запускаем анимацию

                                var scrollTop = $(window).scrollTop();
                                var posLogo = $('.is-header .is-header__logo:visible').offset();
                                var posLogoHeight = $('.is-header .is-header__logo:visible').height();
                                var loaderLogoWrapper = $('.loader .loader__preview .logo');
                                var loaderLogoOffset = loaderLogoWrapper.offset();
                                var headerWrapperWidth = $('.is-header .l-wrapper').width();
                                var headerWrapperHeight = $('.is-header .l-wrapper').height();
                                var docWidth =  $(document).width();
                                var docHeight = $(window).height();

                                //считаем левую позицию
                                var leftPos = -((headerWrapperWidth / 2) - (loaderLogoWrapper.width() / 2) + 61);

                                //считаем верхнюю позицию
                                if($('.is-header').hasClass('is-header__fixed') || $('.is-header').hasClass('header--white')) {
                                    var topPos = (docHeight / 2) - (docHeight-headerWrapperHeight) - 2;
                                } else {
                                    var topPos = (docHeight / 2) - (docHeight-headerWrapperHeight) + 16;
                                }

                                loaderLogoWrapper.css({'transform':''});
                                loaderLogoWrapper.css({'transform':'translate'+'('+leftPos+'px'+','+topPos+'px'+')'});
                                moduleApp.animateSettings();

                            }, 1000)

                            setTimeout(function () {

                                $(".loader").css({'display':'none'});
                                $('.is-header .is-header__logo').css({'opacity':'1'});

                            }, 2500)
                        }
                        else {
                            setTimeout( function () {
                                $(".loader .preview__logo").fadeOut("slow");
                                setTimeout( function () {
                                    $(".loader").fadeOut("slow");
                                    enableScrollPage();
                                    moduleApp.animateSettings();
                                }, 400)
                            }, 400)
                        }

                    }
                }, 200);

                var intervalCheckReadyStateVideo = setInterval(function () {

                    if(video) {

                        var lazyLoadSrc = $(video).find('source').attr('data-src');

                        if(lazyLoadSrc) {

                            $(video).find('source').removeAttr('data-src');
                            $(video).find('source').attr('src', lazyLoadSrc);
                            video.load();

                            clearInterval(intervalCheckReadyStateVideo);

                        } else {
                            clearInterval(intervalCheckReadyStateVideo);
                        }

                    } else {
                        clearInterval(intervalCheckReadyStateVideo);
                    }

                }, 10)

            }
            else {
                setTimeout(function () {
                    $(".loader").fadeOut("slow");
                }, 200);
            }
        }

        function hiddenScrollPage() {
            $('body').css({
                'position': 'fixed',
                'overflow-y': 'scroll',
                'width': '100%',
                'height': '100%'
            });
        }

        function enableScrollPage() {
            $('body').css({
                'position': '',
                'overflow-y': '',
                'width': '',
                'height': ''
            });
        }

        function checkFixedHeader() {
            if($('.is-header').hasClass('is-header__fixed')) {
                return true;
            } else {
                return false;
            }
        }

        function checkWhiteHeader() {
            if($('.is-header').hasClass('header--white')) {
                return true;
            } else {
                return false;
            }
        }

        function animateControl() {
            if($('body').hasClass('animate')) {
                return true;
            } else {
                return false;
            }
        }

        function startAnimateWow() {
            if($('body').hasClass('animate')) {
                var widthDoc = $(document).width();

                if(widthDoc >= 1024) {
                    $(window).scrollTop(0);
                    new WOW({
                        //mobile: false
                    }).init();
                }
            }
        }

        function setCookie(name,value,days) {
            var expires = "";
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days*24*60*60*1000));
                expires = "; expires=" + date.toUTCString();
            }
            document.cookie = name + "=" + (value || "")  + expires + "; path=/";
        }

        function getCookie(name) {
            var nameEQ = name + "=";
            var ca = document.cookie.split(';');
            for(var i=0;i < ca.length;i++) {
                var c = ca[i];
                while (c.charAt(0)==' ') c = c.substring(1,c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
            }
            return null;
        }
    },
    /*'videoIntro': function ($thisModule) {
        var $ctr = $('#ctr');

        var $bar = $('.index-loader__p-bar');

        var $skip = $('.index-loader__skip');

        var $skipVideo = $('.page-index__video-intro__skip');

        var skipIntro = false;

        var $video = $thisModule.find('video');

        var $loader = $('.index-loader');

        if (window.localStorage.showPreview !== '1') {
            $bar.addClass('init');
            $video.attr('src', $video.data('src'));
            $ctr.prop('Counter', 0).animate({
                Counter: $ctr.text()
            }, {
                duration: 3000,
                easing: 'swing',
                step: function (now) {
                    $ctr.text(Math.ceil(now));
                },
                complete: function () {
                    // window.scrollTo(0, 0);
                    if (!skipIntro) {
                        if (device.mobile()) {
                            $thisModule.hide();
                            $loader.fadeOut("slow");
                        } else {
                            $video[0].play();
                            $loader.fadeOut("slow");
                        }
                    }
                }
            });
            // $video.on('canplaythrough', function () {
            // 	setTimeout(function () {
            // 		window.scrollTo(0,0);
            // 		$video[0].play();
            // 		$loader.fadeOut("slow");
            // 	}, 4010);
            // });
            $video.on('ended', function () {
                window.scrollTo(0, 0);
                $thisModule.fadeOut(1000, function () {
                });
            });
            window.localStorage.showPreview = '1';
        } else {
            $thisModule.fadeOut(1000);
            $loader.fadeOut("slow");
        }

        $skip.on('click', function (e) {
            e.preventDefault();
            $thisModule.fadeOut(1000);
            $loader.fadeOut("slow");
            skipIntro = true;
            window.localStorage.showPreview = '1';
        });

        $skipVideo.on('click', function (e) {
            e.preventDefault();
            $video[0].pause();
            $thisModule.fadeOut(1000);
            $loader.fadeOut("slow");
            skipIntro = true;
            window.localStorage.showPreview = '1';
        });
    },*/
    'buttonFill': function () {
        $('.button--fill-gradient-js').mousemove(function (e) {
            var $this = $(e.currentTarget);
            var $thisGradient = $this.find('.button__gradient');
            var thisOffset = $this.offset();
            var thisWidth = $this.outerWidth();

            var position = parseInt((moduleApp.getCursorPosition(e)[0] - thisOffset.left) / thisWidth * 100, 10) - 150;

            $thisGradient.css({
                right: position + '%',
            });
        });
    },
    'openJobModal': function () {
        var btn = $('.js-open-modal');
        btn.fancybox({
            wrapCSS: 'fb-job-modal',
            padding: 0,
            margin: 0,
            autoScale: false,
            fitToView: false,
            openSpeed: 300,
            closeSpeed: 300,
            nextSpeed: 300,
            prevSpeed: 300,
            toolbar: false,
            afterShow: function () {
                conf.nodeBody.addClass('body-state-fancy');
                // this modal parent
                var $thisFancy = $('.fancybox-inner');
                // moduleApp.executeModules($thisFancy);
                $('.js-acc-open').click(function (e) {
                    e.preventDefault();
                    var $this = $(this);
                    var $currentAccItems = $this.closest('.is-accordion').find('.ia-item');
                    var $currentAccWrapper = $this.closest('.is-accordion').find('.ia-content-wrapper');

                    var $currentItem = $this.closest('.ia-item');
                    var $currentWrapper = $currentItem.find('.ia-content-wrapper');
                    var cH = $currentItem.find('.ia-content').height();

                    if ($currentItem.hasClass('active')) {
                        if (!$this.hasClass('state-unclose')) {
                            $currentItem.removeClass('active');
                            $currentWrapper.removeAttr('style');
                        }
                    } else {
                        $currentAccItems.removeClass('active');
                        $currentItem.addClass('active');
                        $currentAccWrapper.removeAttr('style');
                        $currentWrapper.attr('style', 'height:' + cH + 'px;');
                    }
                });
            },
            beforeClose: function () {
                conf.nodeBody.removeClass('body-state-fancy');
            },
            afterClose: function () {
                conf.fancyStatus = false;
            }
        });
        var upload = $('.js-drop-upload');
        upload.uploadFile({
            url: "upload.php",
            dragDrop: true,
            fileName: "myfile",
            returnType: "json",
            showDelete: true,
            showDownload:false,
            maxFileCount:1,
            multiple:false,
            showFileCounter: false,
            showFileSize: false,
            showAbort: false,
            uploadStr: 'Upload a file',
            dragDropStr: '<span>or drag and drop here</span>',
            acceptFiles:".pdf, application/msword, .doc, .docx",
            deleteCallback: function (data, pd) {
                for (var i = 0; i < data.length; i++) {
                    $.post("delete.php", {op: "delete",name: data[i]},
                        function (resp,textStatus, jqXHR) {
                        });
                }
                pd.statusbar.hide();
            }
        });
    },
    'getCursorPosition': function (e) {
        return [
            (window.Event) ? e.pageX : event.clientX + (document.documentElement.scrollLeft ? document.documentElement.scrollLeft : document.body.scrollLeft),
            (window.Event) ? e.pageY : event.clientY + (document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop)
        ];
    },
    'confirmLocationPopup': function () {
        if (appConfig.mobileVersion) {
            $(".js-confirm-location-trigger").detach().appendTo('#globalHeader');
        }
        $window.on('load', function () {
            setTimeout(function () {
                $(".js-confirm-location-trigger").addClass("is-active");
            }, 3000);
            $(".js-hide-check-location").on("click", function () {

                $(".js-confirm-location-trigger").removeClass("is-active");
            })
        });
    },
    'inViewLoader': function () {
        var hiddenClass = 'view-hidden';
        var $inView = $('.' + hiddenClass);

        $inView.each(function (i, e) {
            var $e = $(e);
            var r1 = parseInt(Math.random() * 1000) + 500;
            var r2 = parseInt(Math.random() * 200);
            var string = 'transition-duration:' + r1 + 'ms;transition-delay:' + r2 + 'ms;transition-property:opacity;';
            var style = $e.attr('style');
            if (style) {
                string = string + style;
            }
            $e.attr('style', string);

        });

        $inView.on('inview', function (event, isInView) {
            if (isInView) {
                $(this).removeClass(hiddenClass);
            }
        });
    },
    'startupMessage': function () {
        if (appConfig.startupMessage.title && appConfig.startupMessage.message) {
            var t = '<div class="fb-modal-default">';
            t += '<a class="is-cross-link" data-gclick="closeFancy" href="#"><img src="/assets/img/icon-cross.svg" alt=""></a>';
            t += '<div class="md-body">' + appConfig.startupMessage.message + '</div>';
            t += '</div>';

            var thisFancyConfig = $.extend({}, conf.fancyDefault, {
                content: t
            });

            $.fancybox.open(t, thisFancyConfig);
        }
    },
    'loaderImages': function ($img) {

        var inviewFlag = true;
        if (!$img) {
            $img = $('[data-img]');
        }

        $img.each(function (i, thisImg) {

            var $thisImage = $(thisImg);
            var $parentNode = $thisImage.parent();

            var imgUrl = $thisImage.data().img || '';
            var imgMiddle = $thisImage.data().middle || false;
            var imgVisible = $thisImage.data().visible || false;
            var nativeStyle = $thisImage.attr('style') || '';

            if (!imgVisible) {
                $thisImage.addClass('sfx-hidden');
            }

            $thisImage.removeAttr('data-img');
            var tempImage = new Image();
            tempImage.src = imgUrl;
            tempImage.onload = function () {


                var r1 = moduleApp.getRandom(500, 600);
                var r2 = moduleApp.getRandom(0, 5);

                if ($thisImage.is('img')) {
                    $thisImage.attr('src', imgUrl);


                    if (imgMiddle) {
                        var middleArray = {};
                        middleArray.width = parseInt($thisImage.width());
                        middleArray.height = parseInt($thisImage.height());
                        middleArray.halfTop = parseInt(middleArray.height / 2);
                        middleArray.halfLeft = parseInt(middleArray.width / 2);
                        nativeStyle += 'position:absolute;display:block;top:50%;left:50%;width:' + middleArray.width + 'px;height:' + middleArray.height + 'px;margin:-' + middleArray.halfTop + 'px 0 0 -' + middleArray.halfLeft + 'px;';
                    }

                    $thisImage.attr('style', nativeStyle + 'transition-property:opacity;transition-duration:' + r1 + 'ms;transition-delay:' + r2 + 'ms;');
                } else {
                    $thisImage.attr('style', nativeStyle + 'background-image:url(' + imgUrl + ');transition-property:opacity;transition-duration:' + r1 + 'ms;transition-delay:' + r2 + 'ms;');
                }
                if ($parentNode.hasClass('js-zoom-image')) $parentNode.zoom();
            };
        });

        if (inviewFlag) {
            $img.on('inview', function (event, isInView, howView) {
                if (isInView) {
                    var $this = $(this);
                    $(this).removeClass('sfx-hidden');
                }
            });
        } else {
            $img.removeClass('sfx-hidden');
        }
    },
    'vPlayer': function ($thisModule) {
        var $switchSound = $thisModule.find('.mute');
        var $volume = $thisModule.find('.volume');
        var videoName = $thisModule.find('.video-js').attr('id');
        var video = videojs(videoName);
        var currentVolume = 0;

        $switchSound.on('click', function () {
            $(this).toggleClass('active');
            $volume.toggleClass('noAnim');

            if (video.muted()) {
                video.muted(false);
            } else {
                video.muted(true);
            }
        })
        video.on('ready', function (event) {
            video.src($thisModule.find('.video-js').data('src'));
            $volume.addClass('stop');
        });
        video.on('pause', function (event) {
            $volume.addClass('stop');
        });
        video.on('ended', function (event) {
            $volume.addClass('stop');
        });
        video.on('play', function (event) {
            $volume.removeClass('stop');
        });

        $window.on('scroll.setVideo', function () {
            /*if ($window.scrollTop() + $window.outerHeight() > $thisModule.offset().top) {
                console.log('scroll.setVideo')
                video.src($thisModule.find('.video-js').data('src'));
                $window.off('scroll.setVideo');
            }*/
        }).trigger('scroll');


    },

    'formFocused': function () {
        var $inputs = $('input, textarea');

        $inputs.on('change', function () {
            var $this = $(this);
            $this.val().length > 0 ? $this.closest('.is-form__field').addClass('state-focused') : $this.closest('.is-form__field').removeClass('state-focused');
        });

        $inputs.each(function (i, e) {
            var $this = $(e);
            $this.val().length > 0 ? $this.closest('.is-form__field').addClass('state-focused') : $this.closest('.is-form__field').removeClass('state-focused');
        });
    },
    'form-validation': function ($thisModule, thisCallback) {

        thisCallback = thisCallback || false;

        var params = {
            'fields': {
                'attr': 'validation',
                'parentClass': 'is-form-field',
                'parentErrorState': 'state-error',
                'errorNode': 'ff-error-parent',
                'formFocusClass': 'state-focus',
                'formHasValueClass': 'state-has-value',
                'fromInitedClass': 'state-inited',
                'fieldInitidClass': '_field-inited',
                'initialNodes': $(null),
                'passwordFields': {
                    'primaryNode': $(null),
                    'secondaryNode': $(null)
                },
                'maskedBlank': '–'
            },
            'form': {
                'moduleParent': $thisModule,
                'parent': $thisModule.find('form'),
                'submitLinkClass': 'js-form-submit',
                'validFormClass': 'form-state-valid',
                'invalidFormClass': 'form-state-invalid',
                'progressFormClass': 'form-state-progress',
                'progressButtonClass': 'button-state-progress'
            },
            'inner': {
                'submitCallback': thisCallback,
                'realTimeCheck': false,
                'defaultFieldData': {
                    'mask': 'none',
                    'require': true,
                    'visible': true,
                    'error': '',
                    'cleaveMask': false
                }
            }
        };

        var methods = {
            'init': function () {
                methods.initFields();
                methods.initEvents();
                methods.initFieldsMarkup();
                methods.initFormStatus();
            },
            // 'grecaptchaInit': function () {
            //     grecaptcha.ready(function () {
            //         grecaptcha.execute('6Lc7nrAZAAAAAMVO2PvYe3g7SiJRVVNkmIcLiIME', {action: 'create_comment'}).then(function (token) {
            //             params.form.parent.prepend('<input type="hidden" name="g-recaptcha-response" value="' + token + '">');
            //         });
            //     });
            // },
            'initFields': function () {
                params.fields.initialNodes = params.form.parent.find('[data-' + params.fields.attr + ']');
            },
            'initEvents': function () {
                params.form.parent.on('submit', methods.eventFormSubmit);
                params.form.parent.find('.' + params.form.submitLinkClass).on('click', methods.eventSubmitLinkClick);
                $thisModule.on('keypress input change', '[data-' + params.fields.attr + ']', methods.eventChangeFields);
                $thisModule.on('keydown', '[data-' + params.fields.attr + ']', methods.eventFormSubmitEnter);
                $thisModule.on('focus', '[data-' + params.fields.attr + ']', methods.eventFocusEnter);
                $thisModule.on('blur', '[data-' + params.fields.attr + ']', methods.eventFocusLeave);
            },
            'initFieldsMarkup': function () {
                params.form.parent.find('[data-' + params.fields.attr + ']:not(.' + params.fields.fieldInitidClass + ')').each(function (i, thisField) {
                    var $thisField = $(thisField);
                    var thisFieldDataRaw = $thisField.data()[params.fields.attr];
                    var thisFieldData = $.extend({}, params.inner.defaultFieldData, thisFieldDataRaw);

                    // error message
                    if (thisFieldData.error) {
                        $thisField.closest('.' + params.fields.parentClass).append('<div class="' + params.fields.errorNode + '">' + thisFieldData.error + '</div>');
                    }

                    // password fields
                    if (thisFieldData.mask == "passwordPrimary") {
                        params.fields.passwordFields.primaryNode = $thisField;
                    }
                    if (thisFieldData.mask == "passwordSecondary") {
                        params.fields.passwordFields.secondaryNode = $thisField;
                    }

                    // masked input
                    if (thisFieldData.cleaveMask) {
                        if (thisFieldData.mask == 'phone') {
                            $thisField.mask("+9 (999) 999 99 99", {
                                placeholder: "–",
                                clearIncomplete: true,
                                positionCaretOnClick: "radixFocus"
                            })
                        } else {
                            try {
                                new Cleave($thisField[0], thisFieldData.cleaveMask);
                            } catch (e) {
                                console.log(e)
                            }
                        }
                    }

                    $thisField.addClass(params.fields.fieldInitidClass);
                    $thisField.closest('.' + params.fields.parentClass).addClass(params.fields.fromInitedClass);
                });
            },
            'initFormStatus': function () {
                if (methods.checkingFieldsAll(params.fields.initialNodes, true)) {
                    params.form.moduleParent.addClass(params.form.validFormClass);
                    params.form.moduleParent.removeClass(params.form.invalidFormClass);
                } else {
                    params.form.moduleParent.removeClass(params.form.validFormClass);
                    params.form.moduleParent.addClass(params.form.invalidFormClass);
                }
            },
            'eventFormSubmitEnter': function (e) {
                // if (e.keyCode == 13) {
                //     methods.eventFormSubmit();
                //     return false;
                // }
                // return true;
            },
            'eventFormSubmit': function () {
                methods.initFields();
                methods.initFieldsMarkup();
                methods.initFormStatus();

                // if form in progress
                if (params.form.moduleParent.hasClass(params.form.progressFormClass)) {
                    return false;
                }

                // enable real time checking
                params.inner.realTimeCheck = true;

                // return state - is a error state
                // if `FALSE` - fields has error and submit is prevent
                // if `TRUE` - no error and form going submit
                var returnState = methods.checkingFieldsAll(params.fields.initialNodes);

                // if fields valid then add progress state to form

                if (returnState == true) {
                    params.form.moduleParent.addClass(params.form.progressFormClass);
                    $('.' + params.form.submitLinkClass).addClass(params.form.progressButtonClass);
                }

                // if submit callback
                if (returnState && params.inner.submitCallback) {
                    /*grecaptcha.ready(function () {
                        grecaptcha.execute('6LcHy7AZAAAAABdTVbJfQtUr4aJaEdcVG7vjTsTt', { action: 'execute' }).then(function (token) {
                            params.form.parent.prepend('<input type="hidden" name="g-recaptcha-response" value="' + token + '">');
                            params.inner.submitCallback();
                        });
                    });*/


                    // grecaptcha.ready(function() {
                    //     grecaptcha.execute('6LebtuEZAAAAALzZIzs76aNWADeYrSadvNSdy1Ds', {action: 'submit'}).then(function(token) {
                    //         // Add your logic to submit to your backend server here.;
                    //         params.form.parent.prepend('<input type="hidden" name="g-recaptcha-response" value="' + token + '">');
                    //
                    //     });
                    // });
                    params.inner.submitCallback();
                    return false;
                }

                return returnState;
            },
            'eventSubmitLinkClick': function (e) {
                e.preventDefault();
                params.inner.submitCallback = function () {
                    var formData = new FormData(params.form.parent[0]);

                    $.ajax({
                        url: params.form.parent.attr('action'),
                        method: 'post',
                        data: formData,
                        cache: false,
                        contentType: false,
                        processData: false,
                        success: function (data) {
                            var k = JSON.parse(data);
                            console.log(k);
                            if(k.script !== undefined) {
                                var resetFormSuccess = params.form.parent[0].reset()
                                var resteCaptchaSuccess = grecaptcha.reset()
                            }
                            var res = $.parseJSON(data);
                            var title = res.title ? res.title : '';
                            var t = '<div class="is-popup is-style">';
                            t += '<div class="is-popup__inner">';
                            t += '<h4 class="is-popup__title">' + title + '</h4>';
                            t += '<p class="is-popup__text">' + res.msg + '</p>';
                            t += '</div>';
                            t += '</div>';
                            if(k.customScript) {
                                t += k.customScript;
                            }

                            $("#time_input").val("");

                            resetFormSuccess,
                                resteCaptchaSuccess,
                                $.fancybox.close();

                            $.fancybox.open(t);
                            params.form.moduleParent.removeClass(params.form.progressFormClass);
                            $('.' + params.form.submitLinkClass).removeClass(params.form.progressButtonClass);

                            if (res.status) {
                                if (res.callback) {
                                    gtag("event", "event_name", {
                                        "event_category": "Contact_us",
                                        "event_action": "send"
                                    });
                                    ym(53110486, 'reachGoal', 'Contact_us');
                                } else {
                                    gtag("event", "event_name", {"event_category": "Review", "event_action": "send"});
                                    ym(53110486, 'reachGoal', 'Review');
                                }
                            }
                            // if (res.success &&
                            // 	typeof params.form.parent.find('.' + params.form.submitLinkClass).attr('data-metric-tag') !== undefined &&
                            // 	params.form.parent.find('.' + params.form.submitLinkClass).attr('data-metric-tag') !== false) {
                            // 	var tag = params.form.parent.find('.' + params.form.submitLinkClass).attr('data-metric-tag');
                            // 	try {
                            // 		// gtag("event" , "event_name", { "event_category": tag, "event_action": "send"});
                            // 		ym(46506570, 'reachGoal', tag);
                            // 	} catch (e) {
                            // 		console.log(e);
                            // 	}
                            // }
                            params.form.parent.find('input[name="g-recaptcha-response"]').remove();
                        },
                        error: function (err) {
                            console.log(err)
                            params.form.parent.find('input[name="g-recaptcha-response"]').remove();
                        }
                    });
                };

                methods.eventFormSubmit();
            },
            'eventChangeFields': function () {
                var $thisField = $(this);
                var thisFieldDataRaw = $.extend({}, $thisField.data()[params.fields.attr], {
                    thisField: $thisField,
                    thisValue: $.trim($thisField.val())
                });

                // checking this field
                if (params.inner.realTimeCheck) {
                    methods.checkingField($thisField);
                }

                // hidden checking all field for submit status
                methods.initFormStatus();

                // double fields logic, trigger secondary on primary
                if (thisFieldDataRaw.mask == "passwordPrimary") {
                    params.fields.passwordFields.secondaryNode.trigger('change');
                }
            },
            'checkingField': function ($thisField, hiddenChecking) {
                // hiddenChecking is check fields but don't change style

                var fieldStatus = true;

                var thisFieldDataRaw = $.extend({}, $thisField.data()[params.fields.attr], {
                    thisField: $thisField,
                    thisValue: $.trim($thisField.val())
                });

                var thisFieldData = $.extend({}, params.inner.defaultFieldData, thisFieldDataRaw);

                // checking empty value

                if ($thisField.val().length === 0) {
                    $thisField.closest('.' + params.fields.parentClass).removeClass(params.fields.formHasValueClass);
                } else {
                    $thisField.closest('.' + params.fields.parentClass).addClass(params.fields.formHasValueClass);
                }

                // checking for mask
                if (methods.checkingMasks[thisFieldData.mask]) {
                    if (methods.checkingMasks[thisFieldData.mask](thisFieldData) || methods.checkingMasks['visibleAndRequire'](thisFieldData)) {
                        // remove error class
                        if (!hiddenChecking) {
                            $thisField.closest('.' + params.fields.parentClass).removeClass(params.fields.parentErrorState);
                        }
                    } else {
                        // added error class
                        if (!hiddenChecking) {
                            $thisField.closest('.' + params.fields.parentClass).addClass(params.fields.parentErrorState);
                        }

                        // added error status
                        fieldStatus = false;
                    }
                }

                return fieldStatus;
            },
            'checkingFieldsAll': function ($fields, hiddenChecking) {
                var checkingParams = {
                    'status': true,
                    'focusFlag': true,
                    'scrollNode': $('body,html'),
                    'scrollSpeed': 300,
                    'scrollDelay': 100,
                    'scrollOffsetShift': 300
                };
                $fields.each(function (i, thisField) {
                    var $thisField = $(thisField);
                    var thisFieldStatus = methods.checkingField($thisField, hiddenChecking);
                    if (!thisFieldStatus) {
                        checkingParams.status = false;

                        // scroll to error field and focus
                        if (checkingParams.focusFlag && !hiddenChecking && !$thisField.is(':checkbox')) {
                            checkingParams.focusFlag = false;
                            $thisField.focus();
                        }
                    }
                });
                return checkingParams.status;
            },
            'checkingMasks': {
                'text': function (data) {
                    return (data.thisValue.length > 0);
                },
                'text1000': function (data) {
                    return (data.thisValue.length < 1001);
                },
                'text1000require': function (data) {
                    return (data.thisValue.length < 1001 && data.thisValue.length > 0);
                },
                'length4': function (data) {
                    if (data.thisValue.length > 4) {
                        $(data.thisField).val(data.thisValue.slice(0, 4));
                    }
                    return (data.thisValue.length == 4);
                },
                'length6': function (data) {
                    if (data.thisValue.length > 6) {
                        $(data.thisField).val(data.thisValue.slice(0, 6));
                    }
                    return (data.thisValue.length == 6);
                },
                'length10': function (data) {
                    return (data.thisValue.length > 9);
                },
                'length6': function (data) {
                    return (data.thisValue.length > 5);
                },
                'phone': function (data) {
                    return (data.thisValue.length === 18);
                },
                'textInn': function (data) {
                    return (data.thisValue.length === 12);
                },
                'textBik': function (data) {
                    return (data.thisValue.length === 9);
                },
                'email': function (data) {
                    return (/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(data.thisValue));
                },
                'phone&email': function (data) {
                    var res = false;
                    var phone = (/^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/.test(data.thisValue));
                    if (!phone) {
                        var email = (/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(data.thisValue));
                    }
                    if (phone == true || email == true) res = true;
                    return res;
                },
                'select': function (data) {
                    return !(data.thisValue == 0);
                },
                'checkbox': function (data) {
                    return (data.thisField.is(':checked'));
                },
                'customRadio': function (data) {
                    return (data.thisValue.length > 0 && data.thisValue != 0);
                },
                'visibleAndRequire': function (data) {
                    // invisible field
                    if (data.visible && !data.thisField.closest('.' + params.fields.parentClass).is(':visible')) {
                        return true;
                    }

                    // not require field
                    if (!data.require && data.thisValue.length > 0) {
                        return true;
                    }

                    // return default
                    return false;
                },
                'passwordPrimary': function (data) {
                    return (data.thisValue.length > 5);
                },
                'passwordSecondary': function (data) {
                    return (data.thisValue.length > 5 && (params.fields.passwordFields.primaryNode.val() === params.fields.passwordFields.secondaryNode.val()));
                },
                'selectChosen': function (data) {
                    return (data.thisValue.length > 0);
                },
                'postCode': function (data) {
                    return (/^\d+$/.test(data.thisValue) && data.thisValue.length === 6);
                },
                'groupRadio': function (data) {
                    return data.thisField.closest('.field-group').find('input:checked').length;
                },
                'groupCheckbox': function (data) {
                    return data.thisField.closest('.field-group').find('input:checked').length;
                }
            },
            'eventFocusEnter': function () {
                $(this).closest('.' + params.fields.parentClass).addClass(params.fields.formFocusClass);
            },
            'eventFocusLeave': function () {
                $(this).closest('.' + params.fields.parentClass).removeClass(params.fields.formFocusClass)
            }
        };

        methods.init();

    },
    'getColor': function (img) {
        var context = document.createElement('canvas').getContext('2d');
        if (typeof img == 'string') {
            var src = img;
            img = new Image;
            img.setAttribute('crossOrigin', '');
            img.src = src;
        }
        context.imageSmoothingEnabled = true;
        context.drawImage(img, 0, 0, 1, 1);
        return context.getImageData(1, 1, 1, 1).data.slice(0, 3);
    },
    'bold-slider': function ($thisModule) {
        var touch = appConfig.mobileVersion ? true : false;
        var $main = $thisModule.find('.js_gallery-top'),
            $thumbs = $thisModule.find('.js_gallery-thumbs');
        var galleryThumbs = new Swiper($thumbs, {
            slidesPerView: 'auto',
            spaceBetween: 2,
            allowTouchMove: touch,
            preloadImages: false,
            lazy: {
                loadPrevNext: true,
            },
            // slideToClickedSlide: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            breakpoints: {
                480: {
                    // slidesPerView: 3,
                    // spaceBetween: 10
                },
                767: {
                    // slidesPerView: 'auto',
                    // spaceBetween: 12
                }
            }
        });
        var swiper = new Swiper($main, {
            slidesPerView: 1,
            spaceBetween: 2,
            preloadImages: false,
            lazy: {
                loadPrevNext: true,
            },
            navigation: {
                prevEl: $thisModule.find('.js_gallery-thumbs-prev'),
                nextEl: $thisModule.find('.js_gallery-thumbs-next'),
            },
            thumbs: {
                swiper: galleryThumbs
            },
            on: {
                init: function () {
                    $thumbs.find('.swiper-slide').eq(0).addClass('swiper-slide-thumb-active--pink');
                },
            }
        });
        $document.on('click', '.swiper-slide-visible:first', function () {
            galleryThumbs.slidePrev();
        });
        $document.on('click', '.swiper-slide-visible:last', function () {
            galleryThumbs.slideNext();
        });
        swiper.on('init slideChange', function () {
            $thumbs.find('.swiper-slide').removeClass('swiper-slide-thumb-active--pink');
            $thumbs.find('.swiper-slide').eq(swiper.activeIndex).addClass('swiper-slide-thumb-active--pink');
        });
    },
    'gallery-games-like': function ($thisModule) {
        var touch = appConfig.mobileVersion ? true : false;
        var swiper,
            $parent = $thisModule.find('.swiper-container'),
            $btnPrev = $thisModule.find('.js_btn-prev'),
            $btnNext = $thisModule.find('.js_btn-next');
            $pagination = $thisModule.find('.swiper-pagination__lines');
        swiper = new Swiper($parent, {
            slidesPerView: 4,
            spaceBetween: 8,
            navigation: {
                nextEl: $btnNext,
                prevEl: $btnPrev
            },
            breakpoints: {
                700: {
                    slidesPerView: 3,
                },
                500: {
                    slidesPerView: 2,
                    slidesPerColumn: 2,
                }
            },
            pagination: {
                el: $pagination,
            },
        });
    },
    'gallery-slider-event': function ($thisModule) {
        var $containerThumbs = $('.gallery-thumbs');
        var $containerTop = $('.gallery-top');
        var galleryThumbs = new Swiper($containerThumbs, {
            spaceBetween: 5,
            slidesPerView: 10,
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            preloadImages: false,
            lazyLoading: true,
            lazy: {
                loadPrevNext: true,
            },
            breakpoints: {
                1023: {
                    slidesPerView: 2,
                    loop: true,
                    loopedSlides: 3,
                    spaceBetween: 10,
                    freeMode: false,
                    centeredSlides: true,
                    initialSlide: 2
                }
            }
        });
        var locationTop = new Swiper($containerTop, {
            spaceBetween: 10,
            preloadImages: false,
            lazyLoading: true,
            lazy: {
                loadPrevNext: true,
            },
            navigation: {
                nextEl: '.navigation-top .c-slider__nav-item--next',
                prevEl: '.navigation-top .c-slider__nav-item--prev',
            },
            thumbs: {
                swiper: galleryThumbs
            },
            on: {
                init: function () {
                    $containerThumbs.find('.swiper-slide').eq(0).addClass('swiper-slide-thumb-active');
                },
            }
        });
        locationTop.on('init slideChange', function () {
            $containerThumbs.find('.swiper-slide').removeClass('swiper-slide-thumb-active');
            $containerThumbs.find('.swiper-slide').eq(locationTop.activeIndex).addClass('swiper-slide-thumb-active');
        });
    },
    'gallery-slider-form': function ($thisModole) {
        var $containerThumbs = $('.gallery-thumbs-enquiry-form');
        var $containerTop = $('.gallery-slider-enquiry-form');
        var galleryThumbs = new Swiper($containerThumbs, {
            spaceBetween: 24,
            slidesPerView: 4,
            loop: true,
            centeredSlides: true,
            slideToClickedSlide: true,
            navigation: {
                nextEl: '.navigation-gallery .swiper-gallery-next',
                prevEl: '.navigation-gallery .swiper-gallery-prev',
            },
            breakpoints: {
                1200: {
                    spaceBetween: 20,
                },
                1000: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                800: {
                    slidesPerView: 3,
                    spaceBetween: 16,
                },
                560: {
                    slidesPerView: 2,
                    spaceBetween: 16,
                    centeredSlides: false
                },
            }
        });
        var galleryTop = new Swiper($containerTop, {
            spaceBetween: 10,
            loop: true,
            navigation: {
                nextEl: '.navigation-gallery .swiper-gallery-next',
                prevEl: '.navigation-gallery .swiper-gallery-prev',
            },
            thumbs: {
                swiper: galleryThumbs
            },
        });

        var backgroundVideoActive = document.querySelector('.background-active');

        $('.page-index .page-index__enquiry-form .left__side-form .form__gallery .gallery .image').click(function () {

            $dataIndex = $(this).attr('data-index-image');

            window.selectGalleryEnquiryFormIndexImage = $dataIndex;

            galleryTop.slideTo($dataIndex)

            if($dataIndex) {
                $modal = $('.modal.modal-picture');
                $modal.addClass('active')
                $(backgroundVideoActive).addClass('active');
            }
        })
    },
    'intro-slider': function ($thisModule) {
        var $containerThumbs = $('.intro-thumbs');
        var $containerTop = $('.intro-top');
        var introThumbs = new Swiper($containerThumbs, {
            spaceBetween: 5,
            slidesPerView: 5,
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            preloadImages: false,
            lazyLoading: true,
            lazy: {
                loadPrevNext: true,
            },
            breakpoints: {
                767: {
                    slidesPerView: 4,
                },
                500: {
                    slidesPerView: 3,
                }
            }
        });
        var introTop = new Swiper($containerTop, {
            spaceBetween: 10,
            preloadImages: false,
            lazyLoading: true,
            lazy: {
                loadPrevNext: true,
            },
            navigation: {
                nextEl: '.intro-top .swiper-intro-next',
                prevEl: '.intro-top .swiper-intro-prev',
            },
            thumbs: {
                swiper: introThumbs
            },
            on: {
                init: function () {
                    $containerThumbs.find('.swiper-slide').eq(0).addClass('swiper-slide-thumb-activeIndex');
                },
            },
        });
        introTop.on('init slideChange', function () {
            $containerThumbs.find('.swiper-slide').removeClass('swiper-slide-thumb-activeIndex');
            $containerThumbs.find('.swiper-slide').eq(introTop.activeIndex).addClass('swiper-slide-thumb-activeIndex');
        });

        setTimeout(function () {
            var slides = $containerThumbs.find('.swiper-slide');
            $(slides).each(function (slideIndex, slideItem) {
                if($(slideItem).is('[data-video-src]')) {
                    var idVideoYoutube = youtube_parser(slideItem.getAttribute('data-video-src'));
                    var imageLink = 'https://img.youtube.com/vi/'+idVideoYoutube+'/mqdefault.jpg';
                    $(slideItem).find('#video').attr('src', imageLink);
                }
            });
        }, 100);

        function youtube_parser(url){
            var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            var match = url.match(regExp);
            return (match&&match[7].length==11)? match[7] : false;
        }
    },
    'items-slider': function ($thisModule) {
        var touch = appConfig.mobileVersion ? true : false;
        var swiper,
            $parent = $thisModule.find('.swiper-container'),
            $btnPrev = $thisModule.find('.js_btn-prev'),
            $btnNext = $thisModule.find('.js_btn-next');
        swiper = new Swiper($parent, {
            slidesPerView: 3,
            spaceBetween: 32,
            navigation: {
                nextEl: $btnNext,
                prevEl: $btnPrev
            },
            breakpoints: {
                767: {
                    slidesPerView: 1,
                }
            }
        });
    },
    'press-slider': function ($thisModule) {
        var touch = appConfig.mobileVersion ? true : false;
        var swiper,
            $parent = $thisModule.find('.swiper-container'),
            $btnPrev = $thisModule.find('.js_btn-prev'),
            $btnNext = $thisModule.find('.js_btn-next');
        swiper = new Swiper($parent, {
            slidesPerView: 3,
            spaceBetween: 57,
            autoHeight: true,
            navigation: {
                nextEl: $btnNext,
                prevEl: $btnPrev
            },
            breakpoints: {
                500: {
                    slidesPerView: 2,
                }
            }
        });
    },
    'games-slider': function ($thisModule) {
        var touch = appConfig.mobileVersion ? true : false;
        var swiper,
            $parent = $thisModule.find('.swiper-container'),
            $btnPrev = $thisModule.find('.js_btn-prev'),
            $btnNext = $thisModule.find('.js_btn-next'),
            $pagination = $thisModule.find('.swiper-pagination__lines');
        swiper = new Swiper($parent, {
            slidesPerView: 2,
            slidesPerColumn: 2,
            slidesPerGroup: 2,
            spaceBetween: 32,
            allowTouchMove: touch,
            watchSlidesVisibility: true,
            preloadImages: false,
            lazyLoading: true,
            lazy: {
                loadPrevNext: true,
            },
            navigation: {
                nextEl: $btnNext,
                prevEl: $btnPrev
            },
            pagination: {
                el: $pagination,
            },
            breakpoints: {
                767: {
                    spaceBetween: 16
                }
            }
        });
        $(document).ready(function () {
            window.dispatchEvent(new Event('resize'));
        });
    },
    'games-index-slider': function ($thisModule) {
        var touch = appConfig.mobileVersion ? true : false;
        var swiper,
            $parent = $thisModule.find('.swiper-container'),
            $btnPrev = $thisModule.find('.swiper-games-prev'),
            $btnNext = $thisModule.find('.swiper-games-next'),
            //$pagination = $thisModule.find('.swiper-pagination__lines');
            swiper = new Swiper($parent, {
                loop: false,
                slidesPerView: 4,
                slidesPerGroup: 4,
                spaceBetween: 20,
                allowTouchMove: true,
                watchSlidesVisibility: true,
                preloadImages: false,
                lazyLoading: true,
                lazy: {
                    loadPrevNext: true,
                },
                watchOverflow: true,
                navigation: {
                    nextEl: $btnNext,
                    prevEl: $btnPrev
                },
                pagination: {
                    //el: $pagination,
                },
                breakpoints: {
                    1023: {
                        spaceBetween: 16,
                    },
                    900: {
                        spaceBetween: 16,
                        slidesPerView: 2,
                        slidesPerColumn: 2,
                        slidesPerGroup: 2,
                    }
                }
            });
        $(document).ready(function () {
            window.dispatchEvent(new Event('resize'));
        });
        $('.page-index__games .games-wrapper .categories__filter .category').click(function () {

            if(!($(this).hasClass('active'))) {

                $('.page-index__games .games-wrapper .categories__filter .category').removeClass('active');
                $(this).addClass('active')

                $dataId = $(this).attr('data-id')

                if($dataId) {

                    var currentURL = window.location.href;

                    var url = new URL(currentURL);

                    var path = url.pathname;

                    var segments = path.split('/').filter(function(segment) {
                        return segment !== '';
                    });

                    var firstSegment = segments[0];

                    $.ajax({
                        url: '/ajax/getGamesIndex.php?category=' + $dataId + "&city=" + firstSegment,
                        method: 'post',
                        dataType: 'html',
                        data: $(this).serialize(),
                        success: function(data){

                            var ob = JSON.parse(data)

                            $('#games__list-swiper').html(ob.html);

                            var touch = appConfig.mobileVersion ? true : false;
                            var swiper,
                                $parent = $thisModule.find('.swiper-container'),
                                $btnPrev = $thisModule.find('.swiper-games-prev'),
                                $btnNext = $thisModule.find('.swiper-games-next'),
                                //$pagination = $thisModule.find('.swiper-pagination__lines');
                                swiper = new Swiper($parent, {
                                    loop: false,
                                    slidesPerView: 4,
                                    slidesPerGroup: 4,
                                    spaceBetween: 20,
                                    allowTouchMove: true,
                                    watchSlidesVisibility: true,
                                    preloadImages: false,
                                    lazyLoading: true,
                                    lazy: {
                                        loadPrevNext: true,
                                    },
                                    watchOverflow: true,
                                    navigation: {
                                        nextEl: $btnNext,
                                        prevEl: $btnPrev
                                    },
                                    pagination: {
                                        //el: $pagination,
                                    },
                                    breakpoints: {
                                        1023: {
                                            spaceBetween: 16,
                                        },
                                        900: {
                                            spaceBetween: 16,
                                            slidesPerView: 2,
                                            slidesPerColumn: 2,
                                            slidesPerGroup: 2,
                                        }
                                    }
                                });
                        }
                    });
                }
            }
        })
    },
    'cp-slider': function ($thisModule) {
        var touch = appConfig.mobileVersion ? true : false;
        var swiper,
            $parent = $thisModule.find('.swiper-container'),
            $btnPrev = $thisModule.find('.js_btn-prev'),
            $btnNext = $thisModule.find('.js_btn-next');
        swiper = new Swiper($parent, {
            slidesPerView: 3,
            preloadImages: false,
            lazyLoading: true,
            lazy: {
                loadPrevNext: true,
            },
            slidesPerColumn: 2,
            slidesPerGroup: 3,
            spaceBetween: 32,
            allowTouchMove: touch,
            navigation: {
                nextEl: $btnNext,
                prevEl: $btnPrev
            },
            breakpoints: {
                767: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                },
                400: {
                    spaceBetween: 0,
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                }
            }
        });
    },
    'pe-slider': function ($thisModule) {
        var touch = appConfig.mobileVersion ? true : false;
        var swiper,
            $parent = $thisModule.find('.swiper-container'),
            $btnPrev = $thisModule.find('.js_btn-prev'),
            $btnNext = $thisModule.find('.js_btn-next');
        swiper = new Swiper($parent, {
            slidesPerView: 3,
            spaceBetween: 32,
            allowTouchMove: touch,
            autoHeight: true,
            navigation: {
                nextEl: $btnNext,
                prevEl: $btnPrev
            },
            breakpoints: {
                1200: {
                    slidesPerView: 2
                },
                1023: {
                    slidesPerView: 'auto',
                    loop: true,
                    loopedSlides: 3,
                    spaceBetween: 10,
                    freeMode: false,
                    centeredSlides: true,
                    initialSlide: 2
                }
            }
        });

        $(document).ready(function () {
            window.dispatchEvent(new Event('resize'));
            setSliderBlockHeight();
        });

        swiper.on('init slideChange', function () {
            setSliderBlockHeight();
        });


        $(window).resize(function() {
            setSliderBlockHeight();
        });

        function setSliderBlockHeight() {

            var mainDivs = $(".past-events__slider .pe-slider.swiper-container .swiper-wrapper .news");
            var maxHeight = 0;
            var screensize = $(window).width();

            for (var i = 0; i < mainDivs.length; ++i) {
                $(mainDivs[i]).css('height', '');
            }

            for (var i = 0; i < mainDivs.length; ++i) {

                if (maxHeight < $(mainDivs[i]).height()) {

                    maxHeight = $(mainDivs[i]).height();
                }
            }

            for (var i = 0; i < mainDivs.length; ++i) {
                $(mainDivs[i]).height(maxHeight);
            }

        }

    },
    'simple-slider': function ($thisModule) {
        var touch = appConfig.mobileVersion ? true : false;
        var swiper,
            $parent = $thisModule.find('.swiper-container'),
            $btnPrev = $thisModule.find('.js_btn-prev'),
            $btnNext = $thisModule.find('.js_btn-next');
        swiper = new Swiper($parent, {
            spaceBetween: 0,
            allowTouchMove: touch,
            preloadImages: false,
            lazy: {
                loadPrevNext: true,
            },
            navigation: {
                nextEl: $btnNext,
                prevEl: $btnPrev
            }
        });
    },
    'recommends-slider': function ($thisModule) {
        var touch = appConfig.mobileVersion ? true : false;
        var swiper,
            $parent = $thisModule.find('.swiper-container'),
            $slide = $thisModule.find('.swiper-slide'),
            $btnPrev = $thisModule.find('.js_btn-prev'),
            $btnNext = $thisModule.find('.js_btn-next');
        if (($slide.length < 6 && !device.mobile()) || ($slide.length < 3 && device.mobile())) {
            $btnPrev.hide();
            $btnNext.hide();
        }
        swiper = new Swiper($parent, {
            slidesPerView: 'auto',
            spaceBetween: 8,
            allowTouchMove: touch,
            preloadImages: false,
            lazy: {
                loadPrevNext: true,
            },
            navigation: {
                nextEl: $btnNext,
                prevEl: $btnPrev
            }
        });
    },
    'center-slider': function ($thisModule) {
        var touch = appConfig.mobileVersion ? true : false;
        var swiper,
            $parent = $thisModule.find('.swiper-container'),
            $slide = $thisModule.find('.swiper-slide'),
            $btnPrev = $thisModule.find('.js_btn-prev'),
            $btnNext = $thisModule.find('.js_btn-next');
        swiper = new Swiper($parent, {
            slidesPerView: 2,
            centeredSlides: true,
            spaceBetween: 1,
            initialSlide: 1,
            allowTouchMove: touch,
            loop: true,
            navigation: {
                nextEl: $btnNext,
                prevEl: $btnPrev
            },
            breakpoints: {
                767: {
                    slidesPerView: 1.285,
                },
            },
        });
    },
    'students-slider': function ($thisModule) {
        var touch = appConfig.mobileVersion ? true : false;
        var swiper,
            $parent = $thisModule.find('.swiper-container'),
            $slide = $thisModule.find('.swiper-slide'),
            $btnPrev = $thisModule.find('.js_btn-prev'),
            $btnNext = $thisModule.find('.js_btn-next');
        swiper = new Swiper($parent, {
            slidesPerView: 3,
            // centeredSlides: true,
            spaceBetween: 56,
            // initialSlide: 1,
            // allowTouchMove: touch,
            loop: true,
            navigation: {
                nextEl: $btnNext,
                prevEl: $btnPrev
            },
            breakpoints: {
                1023: {
                    slidesPerView: 2,
                },
                767: {
                    slidesPerView: 1,
                },
            },
        });
    },
    'academy-slider': function ($thisModule) {
        var touch = appConfig.mobileVersion ? true : false;
        var swiper,
            $parent = $thisModule.find('.swiper-container'),
            $slide = $thisModule.find('.swiper-slide'),
            $btnPrev = $thisModule.find('.js_btn-prev'),
            $btnNext = $thisModule.find('.js_btn-next');
        swiper = new Swiper($parent, {
            slidesPerView: 1.5,
            centeredSlides: true,
            spaceBetween: 20,
            initialSlide: 1,
            allowTouchMove: touch,
            loop: true,
            navigation: {
                nextEl: $btnNext,
                prevEl: $btnPrev
            },
            breakpoints: {
                767: {
                    slidesPerView: 1.2,
                },
            },
        });

        //autoHeight slides
        setMaxHeightSlides();

        $(window).resize(function() {
            setMaxHeightSlides();
        });

        function setMaxHeightSlides() {
            var mainDivs = $(".academy-slider .swiper-container .swiper-slide");
            for (var i = 0; i < mainDivs.length; ++i) {
                $(mainDivs[i]).css('height', '');
            }
            var maxHeight = 0;
            for (var i = 0; i < mainDivs.length; ++i) {
                if (maxHeight < $(mainDivs[i]).height()) {
                    maxHeight = $(mainDivs[i]).height();
                }
            }
            for (var i = 0; i < mainDivs.length; ++i) {
                $(mainDivs[i]).height(maxHeight);
            }
        };
    },
    'reviews-slider': function ($thisModule) {
        var touch = appConfig.mobileVersion ? true : false;
        var swiper,
            $parent = $thisModule.find('.swiper-container'),
            $slide = $thisModule.find('.swiper-slide'),
            $btnPrev = $thisModule.find('.js_btn-prev'),
            $btnNext = $thisModule.find('.js_btn-next');

        swiper = new Swiper($parent, {
            slidesPerView: 3,
            spaceBetween: 48,
            allowTouchMove: touch,
            autoHeight: true,
            navigation: {
                nextEl: $btnNext,
                prevEl: $btnPrev
            },
            breakpoints: {
                889: {
                    slidesPerView: 2,
                },
                629: {
                    slidesPerView: 1
                }
            },
            on: {
                init: function () {
                    setPercentActiveLineSwiper(this)
                },
            },
        });

        swiper.on('init slideChange', function () {

            setPercentActiveLineSwiper(swiper);
        });

        function setPercentActiveLineSwiper(swiper) {

            line__progress_reviews = $('.reviews-outer .line__progress-reviews .active__line');

            slidesPerView = swiper.params.slidesPerView;
            $countSlides = swiper.slides.length
            $activeIndex = swiper.activeIndex;

            percentSlideLine = (($activeIndex + slidesPerView)/$countSlides).toFixed(2)*100;
            percentSlideLine = percentSlideLine.toFixed(2);

            line__progress_reviews.css('width', percentSlideLine + '%').css('width', '+=4px');
        }
    },
    'events-slider': function ($thisModule) {
        var touch = appConfig.mobileVersion ? true : false;
        var swiper,
            $parent = $thisModule.find('.swiper-container'),
            $slide = $thisModule.find('.swiper-slide'),
            $btnPrev = $thisModule.find('.js_btn-prev'),
            $btnNext = $thisModule.find('.js_btn-next');
        swiper = new Swiper($parent, {
            slidesPerView: 1,
            spaceBetween: 48,
            allowTouchMove: touch,
            autoHeight: true,
            loop: false,
            speed: 500,
            autoplay: {
                delay: 5000,
            },
            navigation: {
                nextEl: $btnNext,
                prevEl: $btnPrev
            },
            breakpoints: {
                // 889: {
                //     slidesPerView: 2,
                // },
                // 629: {
                //     slidesPerView: 1
                // }
            }
        });

        $(function(){
            $($btnNext).mouseover(function() {
                $btnPrev.addClass('animation-pause');
                $btnNext.addClass('animation-pause');
            }).mouseout(function(){
                $btnPrev.removeClass('animation-pause');
                $btnNext.removeClass('animation-pause');
            });
        });

        $(function(){
            $($btnPrev).mouseover(function() {
                $btnPrev.addClass('animation-pause');
                $btnNext.addClass('animation-pause');
            }).mouseout(function(){
                $btnPrev.removeClass('animation-pause');
                $btnNext.removeClass('animation-pause');
            });
        });
    },
    'accordion': function ($thisModule) {

        if ($thisModule[0].hasAttribute('mobile-false') && appConfig.mobileVersion) {
            $thisModule.find('.ia-item').addClass('active');
            $thisModule.find('.ia-title-link').hide();
            return false;
        }

        $thisModule.find('.js-acc-open').click(function (e) {
            e.preventDefault();
            var $this = $(this);
            var $currentAccItems = $this.closest('.is-accordion').find('.ia-item');
            var $currentAccWrapper = $this.closest('.is-accordion').find('.ia-content-wrapper');

            var $currentItem = $this.closest('.ia-item');
            var $currentWrapper = $currentItem.find('.ia-content-wrapper');
            var cH = $currentItem.find('.ia-content').height();

            if ($currentItem.hasClass('active')) {
                if (!$this.hasClass('state-unclose')) {
                    $currentItem.removeClass('active');
                    $currentWrapper.removeAttr('style');
                    setTimeout(function () {
                        conf.nodeWindow.trigger('resize');
                    }, 350);
                }
            } else {
                $currentAccItems.removeClass('active');
                $currentItem.addClass('active');
                $currentAccWrapper.removeAttr('style');
                $currentWrapper.attr('style', 'height:' + cH + 'px;');
                setTimeout(function () {
                    conf.nodeWindow.trigger('resize');
                }, 350);
            }
            var altText = $currentItem.find('.ia-title-link').data('alt-text');
            var defaultText = $currentItem.find('.ia-title-link').data('default-text');
            if (typeof altText !== 'undefined' && typeof defaultText !== 'undefined' && altText !== '' && defaultText !== '') {
                if ($currentItem.hasClass('active')) {
                    $currentItem.find('.ia-title-link').text(altText)
                } else {
                    $currentItem.find('.ia-title-link').text(defaultText)
                }
            }
        });
        $('.js-load-more-container').click(function (){
            $thisModule.find('.js-acc-open').click(function (e) {
                e.preventDefault();
                var $this = $(this);
                var $currentAccItems = $this.closest('.is-accordion').find('.ia-item');
                var $currentAccWrapper = $this.closest('.is-accordion').find('.ia-content-wrapper');

                var $currentItem = $this.closest('.ia-item');
                var $currentWrapper = $currentItem.find('.ia-content-wrapper');
                var cH = $currentItem.find('.ia-content').height();

                if ($currentItem.hasClass('active')) {
                    if (!$this.hasClass('state-unclose')) {
                        $currentItem.removeClass('active');
                        $currentWrapper.removeAttr('style');
                        setTimeout(function () {
                            conf.nodeWindow.trigger('resize');
                        }, 350);
                    }
                } else {
                    $currentAccItems.removeClass('active');
                    $currentItem.addClass('active');
                    $currentAccWrapper.removeAttr('style');
                    $currentWrapper.attr('style', 'height:' + cH + 'px;');
                    setTimeout(function () {
                        conf.nodeWindow.trigger('resize');
                    }, 350);
                }
                var altText = $currentItem.find('.ia-title-link').data('alt-text');
                var defaultText = $currentItem.find('.ia-title-link').data('default-text');
                if (typeof altText !== 'undefined' && typeof defaultText !== 'undefined' && altText !== '' && defaultText !== '') {
                    if ($currentItem.hasClass('active')) {
                        $currentItem.find('.ia-title-link').text(altText)
                    } else {
                        $currentItem.find('.ia-title-link').text(defaultText)
                    }
                }
            });
        })
    },
    'g-card': function ($thisModule) {

        var setTilt = function () {
            var tilt = $thisModule.find('.g-card__tilt').tilt({
                glare: true,
                maxGlare: .5,
                maxTilt: -20,
                perspective: 1000,
                reset: false,
            });
            var $shine = $thisModule.find('.g-card__shine');
            tilt.on('change', function (e, transforms) {
                $shine.css({"transform": "translate(" + transforms.tiltX * -3 + "px, " + transforms.tiltY * 3 + "px)"})
            });
            $thisModule.data('tilt', tilt);

            if($('.card__video').length > 0) {
                $('.card__video').trigger('pause');
            }

            $('.g-card .g-card__img img').removeAttr('style');

            $('.g-card .g-card__img video').removeAttr('style');

            var video = $thisModule.find('.card__video')

            if (video.length > 0 && video[0].readyState === 4 ) {

                $thisModule.find('.g-card__img img').css({
                    'opacity': '0',
                    'position': 'absolute',
                    'top': '0',
                    'left': '0',
                    'right': '0',
                    'bottom': '0'
                })

                $thisModule.find('.g-card__img video').css({
                    'position': 'static',
                    'opacity': '1'
                })

                video.trigger('play');
            }
        };

        $thisModule.hover(
            function () {
                if ($thisModule.data('active') !== true && !device.mobile()) {
                    $('.g-card').each(function (gIndex, gItem) {
                        var $gItem = $(gItem);

                        $gItem.removeClass('is-hover');
                        if ($gItem.data('tilt')) {
                            $gItem.data('tilt').tilt.destroy.call($gItem.data('tilt'));
                            $gItem.data('active', false);
                        }
                    });
                    setTilt();
                    $thisModule.addClass('is-hover');
                    $thisModule.data('active', true);
                }
            },
            function () {
            }
        );
    },
    'tilt1': function ($thisModule) {
        var tilt = $thisModule.tilt({
            glare: true,
            maxGlare: .5,
            maxTilt: -20,
            perspective: 1000,
            reset: false,
        });
        var $shine = $thisModule.find('.g-card__shine');
        tilt.on('change', function (e, transforms) {
            $shine.css({"transform": "translate(" + transforms.tiltX * -3 + "px, " + transforms.tiltY * 3 + "px)"})
        });
    },
    'scrollMagicInit': function () {
        var controller = new ScrollMagic.Controller();

        if (device.mobile()) {
            return false;
        }

        $('.scrollmagic').each(function (smIndex, smItem) {
            var $smItem = $(smItem);

            var $parent = $smItem.closest('[data-scrollmagic-parent]');

            var smOptions = $smItem.data('options');

            var animation = {
                ease: Linear.easeNone,
            };

            var offset = 0 - $window.outerHeight() / 2;

            if (smOptions.offset) {
                offset -= smOptions.offset;
            }

            $.extend(animation, smOptions.animation);
            var scene = new ScrollMagic.Scene({
                triggerElement: $parent.length ? $parent[0] : $smItem[0],
                duration: $window.outerHeight() / 3,
                offset: offset,
            })
                .setTween(TweenLite.from($smItem[0], 1, animation))
                .addTo(controller);
        });
    },
    'enjoy-types': function ($thisModule) {
        if (device.mobile()) {
            return false;
        }

        var init = function () {
            var controller = new ScrollMagic.Controller();
            var $peoples = [
                $thisModule.find('.people-container .front'),
                $thisModule.find('.people-container .middle'),
                $thisModule.find('.people-container .back'),
            ]
            var $buttons = [
                $thisModule.find('.is-columns .col:nth-child(1) .book-item__f-row'),
                $thisModule.find('.is-columns .col:nth-child(2) .book-item__f-row'),
                $thisModule.find('.is-columns .col:nth-child(3) .book-item__f-row'),
            ]

            new ScrollMagic.Scene({triggerElement: $thisModule[0], duration: 150, offset: 400,})
                .setTween(TweenLite.from($peoples[0], 1, {scale: 1.2, y: -50, ease: Linear.easeNone,}))
                .addTo(controller);

            new ScrollMagic.Scene({triggerElement: $thisModule[0], duration: 100, offset: 200,})
                .setTween(TweenLite.from($buttons[0], 1, {autoAlpha: 0, ease: Linear.easeNone,}))
                .addTo(controller);

            new ScrollMagic.Scene({triggerElement: $thisModule[0], duration: 150, offset: 350,})
                .setTween(TweenLite.from($peoples[1], 1, {autoAlpha: 0, y: 100, ease: Linear.easeNone,}))
                .addTo(controller);

            new ScrollMagic.Scene({triggerElement: $thisModule[0], duration: 100, offset: 300,})
                .setTween(TweenLite.from($buttons[1], 1, {autoAlpha: 0, ease: Linear.easeNone,}))
                .addTo(controller);

            new ScrollMagic.Scene({triggerElement: $thisModule[0], duration: 150, offset: 500,})
                .setTween(TweenLite.from($peoples[2], 1, {autoAlpha: 0, y: 100, ease: Linear.easeNone,}))
                .addTo(controller);

            new ScrollMagic.Scene({triggerElement: $thisModule[0], duration: 100, offset: 300,})
                .setTween(TweenLite.from($buttons[2], 1, {autoAlpha: 0, ease: Linear.easeNone,}))
                .addTo(controller);
        }

        $window.on('scroll.enjoyTypes', function () {
            if ($window.scrollTop() + $window.outerHeight() > $thisModule.offset().top) {
                init();
                $window.off('scroll.enjoyTypes');
            }
        }).trigger('scroll');
    },
    'gift': function ($thisModule) {
        var $imageWrap = $thisModule.find('.page-gift__image');

        var $background = $thisModule.find('.page-gift__image__background');

        var $imageContainer = $thisModule.find('.page-gift__image__container');

        var $image = $thisModule.find('.page-gift__image__animate');

        var $hiddenElements = $('.is-header, .city-select-mobile, .page-gift__title, .page-gift__container .page-gift__desc,  .page-gift__btn-wrap, .page__crumbs, .is-footer');

        if (device.mobile()) {
            TweenLite.from($imageContainer, 0.5, {
                delay: 0.5,
                autoAlpha: 0,
                ease: Linear.easeNone,
                onComplete: function () {
                    $image.attr('style', '');
                    setTimeout(function () {
                        $imageContainer.addClass('is-transition');
                        $imageContainer.removeClass('is-scaled');
                        $imageWrap.addClass('is-animated');
                    }, 15);
                }
            });

            TweenLite.from($hiddenElements, 0.5, {
                delay: 1,
                autoAlpha: 0,
                ease: Linear.easeNone,
            });
        } else {
            TweenLite.from($image, 0.5, {
                delay: 0.5,
                autoAlpha: 0,
                width: '100vw',
                ease: Linear.easeNone,
                onComplete: function () {
                    $image.attr('style', '');
                    setTimeout(function () {
                        $imageContainer.addClass('is-transition');
                        $imageContainer.removeClass('is-scaled');
                        $imageWrap.addClass('is-animated');
                    }, 15);
                }
            });

            TweenLite.from($background, 0.5, {
                delay: 0.5,
                autoAlpha: 1,
                ease: Linear.easeNone,
                onComplete: function () {
                    $background.hide();
                }
            });

            TweenLite.from($hiddenElements, 0.5, {
                delay: 1,
                autoAlpha: 0,
                ease: Linear.easeNone,
            });
        }
    },
    'set-rate': function ($thisModule) {
        var $star = $thisModule.find('.review__star');
        var $input = $thisModule.find('input');

        $star.on('mouseenter', function (e) {
            var $this = $(this);
            $this.addClass('active');
            $this.prevAll().addClass('active');
        });

        $star.on('mouseleave', function (e) {
            $star.removeClass('active');
        });

        $star.on('click', function (e) {
            var $this = $(this);
            $star.removeClass('active');
            $this.addClass('active');
            $this.prevAll().addClass('active');
            $star.off('mouseenter mouseleave');
            $input.val($star.index(this) + 1)
        })
    },
    'contact-us': function ($thisModule) {
        var $footer = $('.is-footer');

        $window.on('scroll', function () {
            if ($window.scrollTop() + $window.outerHeight() >= $footer.offset().top + 10) {
                $thisModule.addClass('is-absolute');
                // $thisModule.css({
                // 	top: $footer.offset().top - 30 - $thisModule.outerHeight(),
                // });
            } else {
                $thisModule.removeClass('is-absolute');
                // $thisModule.css({
                // 	top: 'auto',
                // });
            }
        });
    },
    'map': function ($thisModule) {
        var init = function () {
            var $map = $thisModule.find('.js-map-map');

            var $links = $thisModule.find('.js-map-link');

            var options = $map.data('options');

            var bounds = new google.maps.LatLngBounds();

            var zoom = options.zoom;

            if ($(window).width() <= 1023) {
                zoom = 6;
            }

            var currentUrlSection = window.location.pathname;
            if(currentUrlSection == '/manchester/') {
                zoom = 17;
            }

            var map = new google.maps.Map($map[0], {
                center: {
                    lat: options.lat,
                    lng: options.lng
                },
                zoom: zoom,
                disableDefaultUI: true,
                zoomControl: true,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.LEFT_CENTER,
                },
                scrollwheel: false,
                styles: [
                    {
                        "featureType": "all",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "weight": "0.5"
                            },
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "labels.text",
                        "stylers": [
                            {
                                "lightness": "-50"
                            },
                            {
                                "saturation": "-50"
                            },
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.country",
                        "elementType": "labels.text",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.province",
                        "elementType": "labels.text",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.locality",
                        "elementType": "labels.text",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.neighborhood",
                        "elementType": "labels.text",
                        "stylers": [
                            {
                                "hue": "#009aff"
                            },
                            {
                                "saturation": "25"
                            },
                            {
                                "lightness": "0"
                            },
                            {
                                "visibility": "off"
                            },
                            {
                                "gamma": "1"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "saturation": "0"
                            },
                            {
                                "lightness": "100"
                            },
                            {
                                "gamma": "2.31"
                            },
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            },
                            {
                                "lightness": "20"
                            },
                            {
                                "gamma": "1"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "saturation": "-100"
                            },
                            {
                                "lightness": "-100"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape.man_made",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "lightness": "0"
                            },
                            {
                                "saturation": "45"
                            },
                            {
                                "gamma": "4.24"
                            },
                            {
                                "visibility": "simplified"
                            },
                            {
                                "hue": "#00ff90"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "saturation": "-100"
                            },
                            {
                                "color": "#f5f5f5"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.text",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            },
                            {
                                "color": "#666666"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "labels.text",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "weight": "0.50"
                            },
                            {
                                "color": "#939393"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "saturation": "-25"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.line",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.station.airport",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "lightness": "50"
                            },
                            {
                                "gamma": ".75"
                            },
                            {
                                "saturation": "100"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    }
                ]
            });

            var markers = [];

            $.each(options.points, function (pIndex, pItem) {

                var marker = new google.maps.Marker({
                    map: map,
                    position: new google.maps.LatLng(pItem.lat, pItem.lng),
                    icon: options.icon,
                });

                markers.push(marker);

                bounds.extend(markers[pIndex].getPosition());

                google.maps.event.addListener(markers[pIndex], "click", function () {
                    $.each(options.points, function (pgIndex, pgItem) {
                        markers[pgIndex].setIcon(options.icon);
                    });
                    markers[pIndex].setIcon(options.iconActive);
                    map.panTo(new google.maps.LatLng(pItem.lat, pItem.lng));

                    $links.each(function (lIndex, lItem) {
                        var $lItem = $(lItem);

                        if (pItem.lat == $lItem.data('options').lat && pItem.lng == $lItem.data('options').lng) {
                            $lItem.addClass('active');
                        } else {
                            $lItem.removeClass('active');
                        }
                    })

                    map.setZoom(17);

                    //вставка значений выбранной локации в поп-ап
                    var uniqueIDClicked = this.uniqueID-1;

                    $selectedLocation = options.points[uniqueIDClicked];

                    $selectLocationBlock = $('.page .wrapper__selected-location__block');
                    $selectLocationBlockCity = $('.page .wrapper__selected-location__block .selected-location__block .location__block .block__city');
                    $selectLocationBlockLocationName = $('.page .wrapper__selected-location__block .selected-location__block .location__block .block__location .location__name');
                    $selectLocationBlockLocationAddress = $('.page .wrapper__selected-location__block .selected-location__block .location__block .block__location .location__address');
                    $selectLocationBlockLocationScheduleWkd = $('.page .wrapper__selected-location__block .selected-location__block .location__block .block__location .schedule .normal');
                    $selectLocationBlockLocationScheduleWknd = $('.page .wrapper__selected-location__block .selected-location__block .location__block .block__location .schedule .normal');
                    $selectLocationBlockLocationDetailLink = $('.page .wrapper__selected-location__block .selected-location__block .location__block .block__location .location__detail-btn .button');

                    $selectLocationBlockCity.html($selectedLocation.CITY_NAME);
                    $selectLocationBlockLocationName.html($selectedLocation.NAME);
                    $selectLocationBlockLocationAddress.html($selectedLocation.ADDRESS);
                    $selectLocationBlockLocationScheduleWkd.html($selectedLocation.WEEKDAY);
                    $selectLocationBlockLocationScheduleWknd.html($selectedLocation.WEEKEND);
                    $selectLocationBlockLocationDetailLink.attr('href', '/contacts/' + $selectedLocation.CODE + '/');

                    $selectLocationBlock.addClass('active');
                });
            });

            if (device.mobile()) {
                //map.fitBounds(bounds);
            }
            else {
                $links.each(function (lIndex, lItem) {
                    var $lItem = $(lItem);

                    if ($lItem.hasClass('active')) {
                        $.each(options.points, function (pIndex, pItem) {
                            if (pItem.lat === $lItem.data('options').lat && pItem.lng === $lItem.data('options').lng) {
                                markers[pIndex].setIcon(options.iconActive);
                            }
                        });
                    }
                });
            }

            $links.on('click', function (e) {
                var $this = $(e.currentTarget);

                e.preventDefault();
                $links.removeClass('active');
                $this.addClass('active');
                map.panTo(new google.maps.LatLng($this.data('options').lat, $this.data('options').lng));
                $.each(options.points, function (pIndex, pItem) {
                    if (pItem.lat === $this.data('options').lat && pItem.lng === $this.data('options').lng) {
                        markers[pIndex].setIcon(options.iconActive);
                    } else {
                        markers[pIndex].setIcon(options.icon);
                    }
                });

                if (device.mobile()) {
                    $('html, body').animate({
                        scrollTop: $map.offset().top,
                    }, 1000);
                }
            });

            var optionCluster = {
                gridSize: 80,
                maxZoom: 11
            };

            markerClusterer = new MarkerClusterer(map, markers, optionCluster);

        };

        $window.on('scroll.setMap', function () {
            if ($window.scrollTop() + $window.outerHeight() > $thisModule.offset().top) {
                moduleApp.scriptLoading('//maps.googleapis.com/maps/api/js?key=' + gglMapApiKey + '&language=en', function () {
                    init();
                });
                $window.off('scroll.setMap');
            }
        }).trigger('scroll');
    },
    'scriptLoading': function (src, callback) {
        var script = document.createElement('script');

        var loaded;

        script.setAttribute('src', src);
        if (callback) {
            script.onreadystatechange = script.onload = function () {
                if (!loaded) {
                    callback();
                }
                loaded = true;
            };
        }
        document.getElementsByTagName('head')[0].appendChild(script);
    },
    'cookies': function ($cookie) {
        if (localStorage.cookiesAgree !== 'yes') {
            setTimeout(function () {
                $cookie.fadeIn(250);
            }, 2000);
        }
        $cookie.find('.cookies__btn').on('click', function (e) {
            e.preventDefault();
            localStorage.cookiesAgree = 'yes';
            $cookie.fadeOut(250);
        });
    },
    'header-nav': function ($tags) {
        var nav = priorityNav.init({
            mainNavWrapper: '.header-nav',
            breakPoint: 0,
            throttleDelay: '50',
            navDropdownLabel: 'More',
        });

        //animate wowJs
        var header = $('.is-header');

        setTimeout( function () {
            if(header.hasClass('is-header__fixed')) {

                $('.header-nav__list .header-nav__item').removeClass('wow')

            } else {

                setTimeout( function () {

                    var delayString = $('.header-nav__list .header-nav__item:last-child').attr('data-wow-delay');
                    var delayNumber = Number(delayString.replace('s', ''));
                    var delayWow = delayNumber + 0.1 + 's';
                    $('.nav__dropdown-wrapper').addClass('wow slideInDown');
                    $('.nav__dropdown-wrapper').attr('data-wow-delay', delayWow);
                    $('.nav__dropdown-wrapper').attr('data-wow-offeset', '0');

                    var delayString = $('.nav__dropdown-wrapper').attr('data-wow-delay');
                    var delayNumber = Number(delayString.replace('s', ''));
                    var delayWow = delayNumber + 0.1 + 's';
                    $('.is-header__middle .h-search').addClass('wow slideInDown');
                    $('.is-header__middle .h-search').attr('data-wow-delay', delayWow);

                    var delayString = $('.is-header__middle .h-search').attr('data-wow-delay');
                    var delayNumber = Number(delayString.replace('s', ''));
                    var delayWow = delayNumber + 0.1 + 's';
                    $('.is-header__right .h-city-select').addClass('wow slideInDown');
                    $('.is-header__right .h-city-select').attr('data-wow-delay', delayWow);

                    var delayString = $('.is-header__right .h-city-select').attr('data-wow-delay');
                    var delayNumber = Number(delayString.replace('s', ''));
                    var delayWow = delayNumber + 0.1 + 's';
                    $('.h-booking.button').addClass('wow slideInDown');
                    $('.h-booking.button').attr('data-wow-delay', delayWow);

                    var delayString = $('.h-booking.button').attr('data-wow-delay');
                    var delayNumber = Number(delayString.replace('s', ''));
                    var durationWow = delayNumber + 0.1 + 's';
                    $('.intro__wrapper .intro__content .content__item .item__title').addClass('wow slideInLeft');
                    $('.intro__wrapper .intro__content .content__item .item__desc').addClass('wow slideInUp');
                    $('.intro__wrapper .intro__content .content__item .item__button').addClass('wow slideInUp');
                    $('.intro__wrapper .intro__content .content__item .item__title').attr('data-wow-duration', durationWow);
                    $('.intro__wrapper .intro__content .content__item .item__desc').attr('data-wow-duration', durationWow);
                    $('.intro__wrapper .intro__content .content__item .item__button').attr('data-wow-duration', durationWow);

                }, 25)
            }
        }, 25)

        $('.nav__dropdown-wrapper').addClass('has-submenu header-nav__item');
        $('.nav__dropdown-wrapper').find('.priority-nav__dropdown').wrap('<div class="submenu__wrapper dropdown__wrapper"></div>');
        $('.nav__dropdown-wrapper').find('.dropdown__wrapper').find('.priority-nav__dropdown').addClass('dropdown__inner');

        //width nav
        setWidthNav();

        $(window).resize(function() {
            setWidthNav();
        });

        function setWidthNav() {

            $headerInner = $('.is-header__inner');
            $headerLeft = $('.is-header__left');
            $headerMiddle = $('.is-header__middle');
            $headerRight = $('.is-header__right');
            $headerNavList = $('.header-nav__list');
            $headerNavDropdown = $('.header-nav .nav__dropdown-wrapper');

            $('.is-header__middle .h-search').addClass('d-none');
            $headerNavDropdown.addClass('active-resize');

            $headerMiddle.find('.is-header__nav').css({'width':'1122px'});
            $freeWidth = $headerInner.outerWidth(true) - $headerLeft.outerWidth(true) - $headerRight.outerWidth(true) - 40;
            $headerMiddle.find('.is-header__nav').css({'width':$freeWidth+'px'});

            setTimeout(function () {
                $totalWidth = $headerNavList.width() + 69;
                $headerMiddle.find('.is-header__nav').css({'width':$totalWidth+'px'});
                $('.is-header__middle .h-search').removeClass('d-none');
                $headerNavDropdown.removeClass('active-resize');
            }, 200)

        }
    },
    'location-swiper': function () {

        var $containerThumbs = $('.location-thumbs');
        var $containerTop = $('.location-top');
        var locationThumbs = new Swiper($containerThumbs, {
            spaceBetween: 5,
            slidesPerView: 3,
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            preloadImages: false,
            lazyLoading: true,
            lazy: {
                loadPrevNext: true,
            }
        });
        var locationTop = new Swiper($containerTop, {
            spaceBetween: 10,
            preloadImages: false,
            lazyLoading: true,
            lazy: {
                loadPrevNext: true,
            },
            navigation: {
                nextEl: '.location-top .swiper-loc-next',
                prevEl: '.location-top .swiper-loc-prev',
            },
            thumbs: {
                swiper: locationThumbs
            },
            on: {
                init: function () {
                    $containerThumbs.find('.swiper-slide').eq(0).addClass('swiper-slide-thumb-active');
                },
            }
        });
        locationTop.on('init slideChange', function () {
            $containerThumbs.find('.swiper-slide').removeClass('swiper-slide-thumb-active');
            $containerThumbs.find('.swiper-slide').eq(locationTop.activeIndex).addClass('swiper-slide-thumb-active');
        });

        var $containerThumbs2 = $('.location-thumbs2');
        var $containerTop2 = $('.location-top2');
        var locationThumbs2 = new Swiper($containerThumbs2, {
            spaceBetween: 5,
            slidesPerView: 3,
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            preloadImages: false,
            lazyLoading: true,
            lazy: {
                loadPrevNext: true,
            }
        });
        var locationTop2 = new Swiper($containerTop2, {
            spaceBetween: 10,
            preloadImages: false,
            lazyLoading: true,
            lazy: {
                loadPrevNext: true,
            },
            navigation: {
                nextEl: '.location-top2 .swiper-loc-next',
                prevEl: '.location-top2 .swiper-loc-prev',
            },
            thumbs: {
                swiper: locationThumbs2
            },
            on: {
                init: function () {
                    $containerThumbs2.find('.swiper-slide').eq(0).addClass('swiper-slide-thumb-active');
                },
            }
        });
        locationTop2.on('init slideChange', function () {
            $containerThumbs2.find('.swiper-slide').removeClass('swiper-slide-thumb-active');
            $containerThumbs2.find('.swiper-slide').eq(locationTop2.activeIndex).addClass('swiper-slide-thumb-active');
        });

        var $containerThumbs3 = $('.location-thumbs3');
        var $containerTop3 = $('.location-top3');
        var locationThumbs3 = new Swiper($containerThumbs3, {
            spaceBetween: 5,
            slidesPerView: 3,
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            preloadImages: false,
            lazyLoading: true,
            lazy: {
                loadPrevNext: true,
            }
        });
        var locationTop3 = new Swiper($containerTop3, {
            spaceBetween: 10,
            preloadImages: false,
            lazyLoading: true,
            lazy: {
                loadPrevNext: true,
            },
            navigation: {
                nextEl: '.location-top3 .swiper-loc-next',
                prevEl: '.location-top3 .swiper-loc-prev',
            },
            thumbs: {
                swiper: locationThumbs3
            },
            on: {
                init: function () {
                    $containerThumbs3.find('.swiper-slide').eq(0).addClass('swiper-slide-thumb-active');
                },
            }
        });
        locationTop3.on('init slideChange', function () {
            $containerThumbs3.find('.swiper-slide').removeClass('swiper-slide-thumb-active');
            $containerThumbs3.find('.swiper-slide').eq(locationTop3.activeIndex).addClass('swiper-slide-thumb-active');
        });

        var $containerThumbs4 = $('.location-thumbs4');
        var $containerTop4 = $('.location-top4');
        var locationThumbs4 = new Swiper($containerThumbs4, {
            spaceBetween: 5,
            slidesPerView: 3,
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            preloadImages: false,
            lazyLoading: true,
            lazy: {
                loadPrevNext: true,
            }
        });
        var locationTop4 = new Swiper($containerTop4, {
            spaceBetween: 10,
            preloadImages: false,
            lazyLoading: true,
            lazy: {
                loadPrevNext: true,
            },
            navigation: {
                nextEl: '.location-top4 .swiper-loc-next',
                prevEl: '.location-top4 .swiper-loc-prev',
            },
            thumbs: {
                swiper: locationThumbs4
            },
            on: {
                init: function () {
                    $containerThumbs4.find('.swiper-slide').eq(0).addClass('swiper-slide-thumb-active');
                },
            }
        });
        locationTop4.on('init slideChange', function () {
            $containerThumbs4.find('.swiper-slide').removeClass('swiper-slide-thumb-active');
            $containerThumbs4.find('.swiper-slide').eq(locationTop4.activeIndex).addClass('swiper-slide-thumb-active');
        });
    },
    'gallery-event-slider': function () {

        var $containerThumbs = $('.gallery-event__small');
        var $containerTop = $('.gallery-event__big');
        var gallerySmall = new Swiper($containerThumbs, {
            spaceBetween: 5,
            slidesPerView: 4,
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            preloadImages: false,
            lazyLoading: true,
            lazy: {
                loadPrevNext: true,
            }
        });
        var galleryBig = new Swiper($containerTop, {
            slidesPerView: 1,
            spaceBetween: 10,
            preloadImages: false,
            lazyLoading: true,
            lazy: {
                loadPrevNext: true,
            },
            navigation: {
                nextEl: '.swiper-top .swiper-next',
                prevEl: '.swiper-top .swiper-prev',
            },
            thumbs: {
                swiper: gallerySmall
            },
            on: {
                init: function () {
                    $containerThumbs.find('.swiper-slide').eq(0).addClass('swiper-slide-thumb-active');
                },
            }
        });
        galleryBig.on('init slideChange', function () {
            $containerThumbs.find('.swiper-slide').removeClass('swiper-slide-thumb-active');
            $containerThumbs.find('.swiper-slide').eq(galleryBig.activeIndex).addClass('swiper-slide-thumb-active');
        });
    },
    'gallery-event-cases-slider': function () {

        var $container = $('.gallery-event-cases');
        var galleryBig = new Swiper($container, {
            slidesPerView: 3,
            spaceBetween: 32,
            preloadImages: false,
            lazyLoading: true,
            lazy: {
                loadPrevNext: true,
            },
            navigation: {
                nextEl: '.page-events-detail__cases .cases .swiper-next',
                prevEl: '.page-events-detail__cases .cases .swiper-prev',
            },
            breakpoints: {
                900: {
                    slidesPerView: 2,
                    loop: true,
                    loopedSlides: 3,
                    spaceBetween: 24,
                    freeMode: false,
                    centeredSlides: true,
                    initialSlide: 2
                }
            }
        });
    },
    'expandText': function() {
        var btnText = $('.js-more-btn');
        btnText.on('click', function () {
            var containerBtn = $(this).parent();
            if($(containerBtn).hasClass('item__button')) {
                var moreText = $(this).parent().parent().find('.item__desc .js-more');
            } else {
                var moreText = $(this).siblings('.js-more');
            }
            if(moreText) {
                if (moreText.hasClass('is-hidden')) {
                    //btnText.html("Read less...");
                    $(moreText).show(400,function() {
                        $(moreText).css("display", "block");
                        moreText.removeClass('is-hidden');
                    }) ;
                    btnText.addClass('is-active');
                } else {
                    //btnText.html( "Read more...");
                    moreText.hide(400).addClass('is-hidden');
                    btnText.removeClass('is-active');
                }
            }
        });
    },
    'masonry': function () {
        setTimeout(function() {
            $('.js-masonry').masonry({
                itemSelector: '.js-masonry-item',
                percentPosition: true
            });
        }, 300)
    },
    'pageDescInnerCards': function() {

        if ($('.desc__inner .inner__cards').length) {

            setTimeout(function () {

                if ($(window).width() > 1023) {
                    setMaxHeightCardDesktop();
                    setPositionItemContentDesktop();
                } else {
                    setMaxHeightCardMobile();
                }

                $( window ).resize(function() {
                    if ($(window).width() > 1023) {
                        setMaxHeightCardDesktop();
                        setPositionItemContentDesktop();
                    } else {
                        setMaxHeightCardMobile();
                    }
                });

            }, 500);

            function setMaxHeightCardDesktop() {
                var mainDivs = $(".desc__inner .inner__cards .cards__item .item__content");
                var maxHeight = 0;
                for (var i = 0; i < mainDivs.length; ++i) {
                    var heightTitle = $(mainDivs[i]).find('.content__title').outerHeight();
                    var heightDesc = $(mainDivs[i]).find('.content__desc').outerHeight();
                    var heightButton = $(mainDivs[i]).find('.content__button').outerHeight();
                    var heightCondition = heightTitle + heightDesc + heightButton + 140;

                    if (maxHeight < heightCondition) {
                        maxHeight = heightCondition;
                    }
                }
                for (var i = 0; i < mainDivs.length; ++i) {
                    $(".desc__inner .inner__cards .cards__item").height(maxHeight);
                }
            }

            function setPositionItemContentDesktop() {

                $('.desc__inner .inner__cards .cards__item').each(function (i, el) {
                    var heightCard = $(this).height();
                    var heightTitle = $(this).find('.item__content .content__title').height();
                    var heightCardSet = (heightCard - heightTitle - 83) + 'px';
                    $(this).find('.item__content').css('top', heightCardSet)
                })
            }

            function setMaxHeightCardMobile() {
                var mainDivs = $(".desc__inner .inner__cards .cards__item .item__content");
                var maxHeight = 0;
                for (var i = 0; i < mainDivs.length; ++i) {
                    var heightTitle = $(mainDivs[i]).find('.content__title').outerHeight();
                    var heightDesc = $(mainDivs[i]).find('.content__desc').outerHeight();
                    var heightButton = $(mainDivs[i]).find('.content__button').outerHeight();
                    var heightCondition = heightTitle + heightDesc + heightButton + 270;


                    if (maxHeight < heightCondition) {
                        maxHeight = heightCondition;
                    }

                }
                for (var i = 0; i < mainDivs.length; ++i) {
                    $(".desc__inner .inner__cards .cards__item").height(maxHeight);
                }
            }
        }
    },
    'pageCardsWorks': function() {

        if ($('.cards-works__inner .inner__cards').length) {

            setTimeout(function () {

                if ($(window).width() > 1023) {
                    setMaxHeightCardDesktop();
                    setPositionItemContentDesktop();
                } else {
                    setMaxHeightCardMobile();
                }

                $( window ).resize(function() {
                    if ($(window).width() > 1023) {
                        setMaxHeightCardDesktop();
                        setPositionItemContentDesktop();
                    } else {
                        setMaxHeightCardMobile();
                    }
                });

            }, 500);

            function setMaxHeightCardDesktop() {
                var mainDivs = $(".cards-works__inner .inner__cards .cards__item .item__content");
                var maxHeight = 0;
                for (var i = 0; i < mainDivs.length; ++i) {
                    var heightTitle = $(mainDivs[i]).find('.content__title').outerHeight();
                    var heightDesc = $(mainDivs[i]).find('.content__desc').outerHeight();
                    var heightCondition = heightTitle + heightDesc + 240;

                    if (maxHeight < heightCondition) {
                        maxHeight = heightCondition;
                    }
                }
                for (var i = 0; i < mainDivs.length; ++i) {
                    $(".cards-works__inner .inner__cards .cards__item").height(maxHeight);
                }
            }

            function setPositionItemContentDesktop() {

                $items = $('.cards-works__inner .inner__cards .cards__item');
                $itemsTitle = $items.find('.item__content .content__title');
                var maxHeight = 0;

                for (var i = 0; i < $itemsTitle.length; ++i) {
                    $($itemsTitle[i]).removeAttr('style');
                    if (maxHeight < $($itemsTitle[i]).height()) {
                        maxHeight = $($itemsTitle[i]).height();
                    }
                }

                for (var i = 0; i < $itemsTitle.length; ++i) {
                    $($itemsTitle[i]).height(maxHeight);
                }

                $items.each(function (i, el) {
                    $($itemsTitle[i]).removeAttr('style');

                    var heightCard = $(this).height();
                    var heightContainer = $(this).find('.item__content').height();
                    var heightTitle = $(this).find('.item__content .content__title').height();
                    var heightDesc = $(this).find('.item__content .content__desc').height();

                    var paddingTop = 160;
                    var paddingBot = 50;
                    var marginTop = 20;
                    var emptyZoneHeight = heightContainer - heightTitle - heightDesc - marginTop;

                    var heightCardSet = emptyZoneHeight + heightTitle + paddingTop + paddingBot;

                    $(this).find('.item__content').css('top', 'calc(100% - ' + heightCardSet+ 'px)')

                });
            }

            function setMaxHeightCardMobile() {
                var mainDivs = $(".cards-works__inner .inner__cards .cards__item .item__content");
                var maxHeight = 0;
                for (var i = 0; i < mainDivs.length; ++i) {
                    var heightTitle = $(mainDivs[i]).find('.content__title').outerHeight();
                    var heightDesc = $(mainDivs[i]).find('.content__desc').outerHeight();
                    var heightCondition = heightTitle + heightDesc + 270;


                    if (maxHeight < heightCondition) {
                        maxHeight = heightCondition;
                    }

                }
                for (var i = 0; i < mainDivs.length; ++i) {
                    $(".cards-works__inner .inner__cards .cards__item").height(maxHeight);
                }
            }
        }
    },
    'anchorScroll': function () {
        $(".js-anchor-link, .anchor-link-js").on("click", function () {
            var el = $(this);
            var dest = el.attr('href');
            if(dest !== undefined && dest !== '') {
                $('html').animate({
                        scrollTop: $(dest).offset().top
                    }, 700
                );
            }
        });
    },
    'headerFixed': function () {

        var header = $('.is-header'),
            selectLocationBoard = $('.city-select-mobile');
        scroll = $(window).scrollTop();
        if (scroll >= 1) {
            header.addClass('is-header__fixed');
            selectLocationBoard.addClass('fixed-location-board')
        }
        else {
            header.removeClass('is-header__fixed');
            selectLocationBoard.removeClass('fixed-location-board')
        }

        $(window).scroll(function(){
            var header = $('.is-header'),
                selectLocationBoard = $('.city-select-mobile');
            scroll = $(window).scrollTop();
            if (scroll >= 60) {
                header.addClass('is-header__fixed');
                selectLocationBoard.addClass('fixed-location-board')
            }
            else {
                header.removeClass('is-header__fixed');
                selectLocationBoard.removeClass('fixed-location-board')
            }
        });
    },
    'headerSetHeightDropdown': function () {

        setHeightDropdown();

        $( window ).resize(function() {
            setHeightDropdown();
        });

        function setHeightDropdown() {
            setTimeout(function () {
                $arContainers = $('.dropdown__wrapper');
                $arContainers.each(function (index) {
                    var maxHeight = $(this).find('.dropdown__inner').outerHeight() + 'px';
                    $(this).css({"maxHeight": maxHeight});
                });
            }, 200);
        }
    },
    'recaptchaResponsive': function () {
        if($('.g-recaptcha').length) {
            setWidthRecaptcha();
            $(window).resize(function() {
                setWidthRecaptcha();
            });
        };
        function setWidthRecaptcha() {
            $(function() {
                var reCaptchaWidth = 302;
                var containerWidth = $('.footer-form').width();
                if(reCaptchaWidth > containerWidth) {
                    var reCaptchaScale = containerWidth / reCaptchaWidth;
                    $('.g-recaptcha').css({
                        'transform':'scale('+reCaptchaScale+')',
                        'transform-origin':'left top'
                    });
                }
            });
        }
    },
    'dropdownClickMobile': function () {
        $(".header-mobile__item.has-submenu .header-mobile__text").click(function() {
            $(this).parent().toggleClass("open");
            $(this).parent().find('.submenu__wrapper').slideToggle(300);
        })
    },
    'citySelectReloadPage': function () {
        $('.js-change-city-reload').click(function (e) {

            var loc = window.location.pathname;
            var dir = loc.substring(0, loc.lastIndexOf('/'));

            //задержка на переход
            e.preventDefault();
            var url = $(this).attr('href');

            $.ajax({
                url: '/api/changeSelectCity/api.php',
                data: {
                    "LOCATION_NAME": $(this).attr('data-location-name'),
                    "LOCATION_CODE": $(this).attr('data-location-code'),
                    "LOCATION_ID": $(this).attr('data-location-id')
                },
                success: function(data) {
                    if(dir != '/jobs') {
                        window.location = url;
                    }
                }
            });
        })
    },
    'citySelectMobile': function () {
        $('.city-select-mobile .city-select__title').click(function () {
            console.log('ddd')
            $(this).parent().toggleClass('open-cities');
            $(this).parent().find('.city-select__wrapper').slideToggle(300);
        })
    },
    'setHeightMainSection': function () {
        if($('.js-height-main-section').length) {
            setHeightMainSection();
            $(window).resize(function() {
                setHeightMainSection();
            });
        };
        function setHeightMainSection() {
            $('.js-height-main-section').height('');
            var globalLayoutHeight = $('.global-layout__content').height();
            $('.js-height-main-section').height(globalLayoutHeight + 'px');
        }
    },
    'detectedChildMenuActive': function () {

        addClassParentMenu();
        $(window).resize(function() {
            addClassParentMenu();
        });

        function addClassParentMenu() {
            var screensize = $(window).width();

            if(screensize <= 767) {
                $arMenu = $('.header-mobile__item');
                $arMenu.each(function (index) {
                    if($(this).hasClass('has-submenu')) {
                        if($(this).find('.links__item').hasClass('active-menu')) {
                            $(this).addClass('active-menu open');
                            $(this).parent().find('.submenu__wrapper').slideToggle(300);
                        }
                    }
                });
            }
            else {
                $arMenu = $('.header-nav__item');
                $arMenu.each(function (index) {
                    if($(this).hasClass('has-submenu')) {
                        if($(this).find('.links__item').hasClass('active-menu')) {
                            $(this).addClass('active-menu');
                        }
                    }
                });

                //button more
                setTimeout(function () {
                    $arMenuWrapper = $('.priority-nav__wrapper');
                    $arMenuMore = $arMenuWrapper.find('.priority-nav__dropdown').find('.header-nav__item');
                    $arMenuMore.each(function (index) {
                        if($(this).hasClass('active-menu')) {
                            $arMenuWrapper.find('.priority-nav__dropdown-toggle').addClass('active-menu');
                        }
                    });
                }, 200);
            }
        }
    },
    'setHeightCardsGames': function () {

        setHeightCardsGames();
        $(window).resize(function() {
            setHeightCardsGames();
        });

        function setHeightCardsGames() {
            var screensize = $(window).width();

            if($('.game-sn__top').length) {
                if(screensize < 1024) {
                    var mainDivs = $('.game-sn__top');
                    var maxHeight = 0;
                    for (var i = 0; i < mainDivs.length; ++i) {
                        var containerContent = $(mainDivs[i]).find('.game-sn__cover');
                        var heightTopRow = containerContent.find('.game-sn__top-row').outerHeight();
                        var heigthLevels = containerContent.find('.game-sn__levels').outerHeight();
                        var gameDesc = containerContent.find('.game-sn__desc').outerHeight();
                        var heightCondition = heightTopRow + heigthLevels + gameDesc + 100;

                        if (maxHeight < heightCondition) {
                            maxHeight = heightCondition;
                        }
                    }
                    for (var i = 0; i < mainDivs.length; ++i) {
                        $(mainDivs[i]).css({"height" : maxHeight});
                    }
                } else {
                    var mainDivs = $('.game-sn__top');
                    for (var i = 0; i < mainDivs.length; ++i) {
                        $(mainDivs[i]).css({"height" : "100%"});
                    }
                }
            }
        }
    },
    'setHeightBackgroundIntroMobile': function () {

        setHeightBackgroundIntroMobile();
        setHeightBackgroundIntroMobile2();
        setHeightBackgroundIntroMobile3();

        $(window).resize(function() {
            setHeightBackgroundIntroMobile();
            setHeightBackgroundIntroMobile2();
            setHeightBackgroundIntroMobile3();
        });

        function setHeightBackgroundIntroMobile() {
            $containerInto = $('.intro__wrapper.intro--end');
            if($containerInto.length) {

                var screensize = $(window).width();
                if(screensize <= 599) {

                    var el = document.querySelector('#intro__img')

                    if(el) {
                        var introWidth = el.parentNode.offsetWidth;
                        var introHeight = el.parentNode.offsetHeight;
                        var img = document.querySelector('#intro__img');
                        var resultImg = getImgSizeInfo(img);
                        var beforeHeight = introHeight - 110 - resultImg['height'] + 1 + 'px';
                        if($(el).hasClass('intro--img-index')) {
                            var afterHeight = introHeight - 410 - resultImg['height'] + 550 + 'px';
                        } else {
                            var afterHeight = introHeight - 110 - resultImg['height'] + 550 + 'px';
                        }

                        try {

                            el.parentNode.querySelector('.background__before').setAttribute('style', 'height:' + beforeHeight);

                        } catch (err) {

                        }

                        try {

                            el.parentNode.querySelector('.background__after').setAttribute('style', 'height:' + afterHeight);

                        } catch (err) {

                        }

                        img.addEventListener('load', function(e) {
                            var resultImg = getImgSizeInfo(e.target);
                            var beforeHeight = introHeight - 110 - resultImg['height'] + 1 + 'px';
                            if($(el).hasClass('intro--img-index')) {
                                var afterHeight = introHeight - 410 - resultImg['height'] + 550 + 'px';
                            } else {
                                var afterHeight = introHeight - 110 - resultImg['height'] + 550 + 'px';
                            }

                            try {

                                el.parentNode.querySelector('.background__before').setAttribute('style', 'height:' + beforeHeight);

                            } catch (err) {

                            }

                            try {

                                el.parentNode.querySelector('.background__after').setAttribute('style', 'height:' + afterHeight);

                            } catch (err) {

                            }
                        });
                    }
                    else {

                        var el = document.querySelector('.intro__background .mobile__img');

                        try {
                            var introWidth = el.parentNode.offsetWidth;
                            var introHeight = el.parentNode.offsetHeight;
                            var img = document.querySelector('.intro__background .mobile__img');
                            var resultImg = getImgSizeInfo(img);
                            var beforeHeight = introHeight - 110 - resultImg['height'] + 1 + 'px';
                            if($(el).hasClass('intro--img-index')) {
                                var afterHeight = introHeight - 410 - resultImg['height'] + 550 + 'px';
                            } else {
                                var afterHeight = introHeight - 110 - resultImg['height'] + 550 + 'px';
                            }

                            try {

                                el.parentNode.querySelector('.background__before').setAttribute('style', 'height:' + beforeHeight);

                            } catch (err) {

                            }

                            try {

                                el.parentNode.querySelector('.background__after').setAttribute('style', 'height:' + afterHeight);

                            } catch (err) {

                            }

                            img.addEventListener('load', function(e) {
                                var resultImg = getImgSizeInfo(e.target);
                                var beforeHeight = introHeight - 110 - resultImg['height'] + 1 + 'px';
                                if($(el).hasClass('intro--img-index')) {
                                    var afterHeight = introHeight - 410 - resultImg['height'] + 550 + 'px';
                                } else {
                                    var afterHeight = introHeight - 110 - resultImg['height'] + 550 + 'px';
                                }

                                try {

                                    el.parentNode.querySelector('.background__before').setAttribute('style', 'height:' + beforeHeight);

                                } catch (err) {

                                }

                                try {

                                    el.parentNode.querySelector('.background__after').setAttribute('style', 'height:' + afterHeight);

                                } catch (err) {

                                }
                            });

                        } catch (err) {

                        }
                    }

                    function getRenderedSize(contains, cWidth, cHeight, width, height, pos){
                        var oRatio = width / height,
                            cRatio = cWidth / cHeight;
                        return function() {
                            if (contains ? (oRatio > cRatio) : (oRatio < cRatio)) {
                                this.width = cWidth;
                                this.height = cWidth / oRatio;
                            } else {
                                this.width = cHeight * oRatio;
                                this.height = cHeight;
                            }
                            this.left = (cWidth - this.width)*(pos/100);
                            this.right = this.width + this.left;
                            return this;
                        }.call({});
                    }

                    function getImgSizeInfo(img) {
                        var pos = window.getComputedStyle(img).getPropertyValue('object-position').split(' ');
                        return getRenderedSize(true,
                            img.width,
                            img.height,
                            img.naturalWidth,
                            img.naturalHeight,
                            parseInt(pos[0]));
                    }

                }
            }
        }

        function setHeightBackgroundIntroMobile2() {
            $containerInto = $('.intro__wrapper.intro--start');
            if($containerInto.length) {

                var screensize = $(window).width();
                if(screensize <= 599) {

                    var el = document.querySelector('#intro__img')

                    if(el) {
                        var introWidth = el.parentNode.offsetWidth;
                        var introHeight = el.parentNode.offsetHeight;
                        var img = document.querySelector('#intro__img');
                        var resultImg = getImgSizeInfo(img);
                        var beforeHeight = introHeight - 110 - resultImg['height'] + 1 + 'px';
                        if($(el).hasClass('intro--img-index')) {
                            var afterHeight = introHeight - 410 - resultImg['height'] + 550 + 'px';
                        } else {
                            var afterHeight = introHeight - 110 - resultImg['height'] + 550 + 'px';
                        }

                        try {

                            el.parentNode.querySelector('.background__before').setAttribute('style', 'height:' + beforeHeight);

                        } catch (err) {

                        }

                        try {

                            el.parentNode.querySelector('.background__after').setAttribute('style', 'height:' + afterHeight);

                        } catch (err) {

                        }

                        img.addEventListener('load', function(e) {
                            var resultImg = getImgSizeInfo(e.target);
                            var beforeHeight = introHeight - 110 - resultImg['height'] + 1 + 'px';
                            if($(el).hasClass('intro--img-index')) {
                                var afterHeight = introHeight - 410 - resultImg['height'] + 550 + 'px';
                            } else {
                                var afterHeight = introHeight - 110 - resultImg['height'] + 550 + 'px';
                            }

                            try {

                                el.parentNode.querySelector('.background__before').setAttribute('style', 'height:' + beforeHeight);

                            } catch (err) {

                            }

                            try {

                                el.parentNode.querySelector('.background__after').setAttribute('style', 'height:' + afterHeight);

                            } catch (err) {

                            }
                        });
                    }
                    else {

                        var el = document.querySelector('.intro__background .mobile__img');

                        try {
                            var introWidth = el.parentNode.offsetWidth;
                            var introHeight = el.parentNode.offsetHeight;
                            var img = document.querySelector('.intro__background .mobile__img');
                            var resultImg = getImgSizeInfo(img);
                            var beforeHeight = introHeight - 110 - resultImg['height'] + 1 + 'px';
                            if($(el).hasClass('intro--img-index')) {
                                var afterHeight = introHeight - 410 - resultImg['height'] + 550 + 'px';
                            } else {
                                var afterHeight = introHeight - 110 - resultImg['height'] + 550 + 'px';
                            }

                            try {

                                el.parentNode.querySelector('.background__before').setAttribute('style', 'height:' + beforeHeight);

                            } catch (err) {

                            }

                            try {

                                el.parentNode.querySelector('.background__after').setAttribute('style', 'height:' + afterHeight);

                            } catch (err) {

                            }

                            img.addEventListener('load', function(e) {
                                var resultImg = getImgSizeInfo(e.target);
                                var beforeHeight = introHeight - 110 - resultImg['height'] + 1 + 'px';
                                if($(el).hasClass('intro--img-index')) {
                                    var afterHeight = introHeight - 410 - resultImg['height'] + 550 + 'px';
                                } else {
                                    var afterHeight = introHeight - 110 - resultImg['height'] + 550 + 'px';
                                }

                                try {

                                    el.parentNode.querySelector('.background__before').setAttribute('style', 'height:' + beforeHeight);

                                } catch (err) {

                                }

                                try {

                                    el.parentNode.querySelector('.background__after').setAttribute('style', 'height:' + afterHeight);

                                } catch (err) {

                                }
                            });

                        } catch (err) {

                        }
                    }

                    function getRenderedSize(contains, cWidth, cHeight, width, height, pos){
                        var oRatio = width / height,
                            cRatio = cWidth / cHeight;
                        return function() {
                            if (contains ? (oRatio > cRatio) : (oRatio < cRatio)) {
                                this.width = cWidth;
                                this.height = cWidth / oRatio;
                            } else {
                                this.width = cHeight * oRatio;
                                this.height = cHeight;
                            }
                            this.left = (cWidth - this.width)*(pos/100);
                            this.right = this.width + this.left;
                            return this;
                        }.call({});
                    }

                    function getImgSizeInfo(img) {
                        var pos = window.getComputedStyle(img).getPropertyValue('object-position').split(' ');
                        return getRenderedSize(true,
                            img.width,
                            img.height,
                            img.naturalWidth,
                            img.naturalHeight,
                            parseInt(pos[0]));
                    }

                }
            }
        }

        function setHeightBackgroundIntroMobile3() {
            $containerInto = $('.intro__wrapper.intro--change-mobile');
            if($containerInto.length) {

                var screensize = $(window).width();
                if(screensize <= 599) {

                    var el = document.querySelector('#intro__img')

                    if(el) {
                        var introWidth = el.parentNode.offsetWidth;
                        var introHeight = el.parentNode.offsetHeight;
                        var img = document.querySelector('#intro__img');
                        var resultImg = getImgSizeInfo(img);
                        var beforeHeight = introHeight - 110 - resultImg['height'] + 1 + 'px';
                        if($(el).hasClass('intro--img-index')) {
                            var afterHeight = introHeight - 410 - resultImg['height'] + 550 + 'px';
                        } else {
                            var afterHeight = introHeight - 110 - resultImg['height'] + 550 + 'px';
                        }

                        try {

                            el.parentNode.querySelector('.background__before').setAttribute('style', 'height:' + beforeHeight);

                        } catch (err) {

                        }

                        try {

                            el.parentNode.querySelector('.background__after').setAttribute('style', 'height:' + afterHeight);

                        } catch (err) {

                        }

                        img.addEventListener('load', function(e) {
                            var resultImg = getImgSizeInfo(e.target);
                            var beforeHeight = introHeight - 110 - resultImg['height'] + 1 + 'px';
                            if($(el).hasClass('intro--img-index')) {
                                var afterHeight = introHeight - 410 - resultImg['height'] + 550 + 'px';
                            } else {
                                var afterHeight = introHeight - 110 - resultImg['height'] + 550 + 'px';
                            }

                            try {

                                el.parentNode.querySelector('.background__before').setAttribute('style', 'height:' + beforeHeight);

                            } catch (err) {

                            }

                            try {

                                el.parentNode.querySelector('.background__after').setAttribute('style', 'height:' + afterHeight);

                            } catch (err) {

                            }
                        });
                    }
                    else {

                        var el = document.querySelector('.intro__background .mobile__img');

                        try {

                        } catch (err) {
                            
                            var introWidth = el.parentNode.offsetWidth;
                            var introHeight = el.parentNode.offsetHeight;
                            var img = document.querySelector('.intro__background .mobile__img');
                            var resultImg = getImgSizeInfo(img);
                            var beforeHeight = introHeight - 110 - resultImg['height'] + 1 + 'px';
                            if($(el).hasClass('intro--img-index')) {
                                var afterHeight = introHeight - 410 - resultImg['height'] + 550 + 'px';
                            } else {
                                var afterHeight = introHeight - 110 - resultImg['height'] + 550 + 'px';
                            }

                            try {

                                el.parentNode.querySelector('.background__before').setAttribute('style', 'height:' + beforeHeight);

                            } catch (err) {

                            }

                            try {

                                el.parentNode.querySelector('.background__after').setAttribute('style', 'height:' + afterHeight);

                            } catch (err) {

                            }

                            img.addEventListener('load', function(e) {
                                var resultImg = getImgSizeInfo(e.target);
                                var beforeHeight = introHeight - 110 - resultImg['height'] + 1 + 'px';
                                if($(el).hasClass('intro--img-index')) {
                                    var afterHeight = introHeight - 410 - resultImg['height'] + 550 + 'px';
                                } else {
                                    var afterHeight = introHeight - 110 - resultImg['height'] + 550 + 'px';
                                }
                                try {

                                    el.parentNode.querySelector('.background__before').setAttribute('style', 'height:' + beforeHeight);

                                } catch (err) {

                                }
                                try {

                                    el.parentNode.querySelector('.background__after').setAttribute('style', 'height:' + afterHeight);

                                } catch (err) {

                                }
                            });

                        }
                    }

                    function getRenderedSize(contains, cWidth, cHeight, width, height, pos){
                        var oRatio = width / height,
                            cRatio = cWidth / cHeight;
                        return function() {
                            if (contains ? (oRatio > cRatio) : (oRatio < cRatio)) {
                                this.width = cWidth;
                                this.height = cWidth / oRatio;
                            } else {
                                this.width = cHeight * oRatio;
                                this.height = cHeight;
                            }
                            this.left = (cWidth - this.width)*(pos/100);
                            this.right = this.width + this.left;
                            return this;
                        }.call({});
                    }

                    function getImgSizeInfo(img) {
                        var pos = window.getComputedStyle(img).getPropertyValue('object-position').split(' ');
                        return getRenderedSize(true,
                            img.width,
                            img.height,
                            img.naturalWidth,
                            img.naturalHeight,
                            parseInt(pos[0]));
                    }

                }
            }
        }
    },
    'setHeightIntroStart': function () {

        setHeightIntroStart();
        $(window).resize(function() {
            setHeightIntroStart();
        });

        function setHeightIntroStart() {
            if($('.intro--start').length) {
                var screensize = $(window).width();
                $('.intro--start').css('height', '');

                var heightContent = $('.intro--start .intro__content').height();
                if(screensize >= 1024) {
                    var heightCondition = 815 - heightContent;

                    $('.intro--start').css({
                        height: heightContent + heightCondition + 'px',
                    });
                }
            }
        }
    },
    'setHeightIntroEnd': function () {

        setHeightIntroEnd();
        $(window).resize(function() {
            setHeightIntroEnd();
        });

        function setHeightIntroEnd() {
            if($('.intro--end').length) {
                var screensize = $(window).width();
                $('.intro--end').css('height', '');

                var heightContent = $('.intro--end .intro__content').height();
                if(screensize >= 1024) {
                    var heightCondition = 815 - heightContent;

                    $('.intro--end').css({
                        height: heightContent + heightCondition + 'px',
                    });
                }
            }
        }
    },
    // 'setHeightIntroCenter': function () {
    //
    //     setHeightIntroCenter();
    //     $(window).resize(function() {
    //         setHeightIntroCenter();
    //     });
    //
    //     function setHeightIntroCenter() {
    //         if($('.intro--center').length) {
    //             var screensize = $(window).width();
    //             $('.intro--center').css('height', '');
    //
    //             var heightContent = $('.intro--center .intro__content').height();
    //             if(screensize >= 1024) {
    //                 var heightCondition = 815 - heightContent;
    //
    //                 $('.intro--center').css({
    //                     height: heightContent + heightCondition + 'px',
    //                 });
    //             }
    //         }
    //     }
    // },
    'dropdownJobsPositions': function () {
        if($('main.page-team').length) {

            setPositionSubmenuWrapper();

            $(window).resize(function() {
                setPositionSubmenuWrapper();
            });

            $(document).click(function (e) {
                setPositionSubmenuWrapper();
                if ($(e.target).closest(".page-positions__title .page-positions__cities .submenu__wrapper").length) {
                    return;
                }
                if ($(e.target).closest(".page-positions__title h2 .title__city, .page-positions__title .title__arrow").length) {
                    $('.page-positions__cities .submenu__wrapper').toggleClass('close-dropdown');
                    $('.page-positions__title .title__city').toggleClass('rotate-arrow');
                    return;
                }
                $('.page-positions__cities .submenu__wrapper').addClass('close-dropdown');
                $('.page-positions__title .title__city').removeClass('rotate-arrow');
            });

            function setPositionSubmenuWrapper() {
                $containerTitle = $('.page-positions__title h2');
                $containerSubmenuWrapperCities = $('.page-positions__title .page-positions__cities .submenu__wrapper');
                if($containerTitle.outerWidth(true) < $containerTitle.find('.title__text').outerWidth(true) + $containerTitle.find('.title__city').outerWidth(true)) {
                    $distanceFromRight = $containerTitle.outerWidth(true) - $containerTitle.find('.title__city').outerWidth(true) + 'px';
                    $distanceFromTop = $containerTitle.outerHeight(true) + 10 + 'px';
                    $containerSubmenuWrapperCities.css('right', $distanceFromRight);
                    $containerSubmenuWrapperCities.css('top', $distanceFromTop);
                }
                else {
                    $distanceFromRight = $containerTitle.outerWidth(true) - $containerTitle.find('.title__text').outerWidth(true) - $containerTitle.find('.title__city').outerWidth(true) + 'px';
                    $distanceFromTop = $containerTitle.outerHeight(true) + 10 + 'px';
                    $containerSubmenuWrapperCities.css('right', $distanceFromRight);
                    $containerSubmenuWrapperCities.css('top', $distanceFromTop);
                }
            }
        }
    },
    'setWidthNameCity': function () {

        if($('.locations__wrapper .l-bottom .l-wrapper .h-left__title').length) {
            autoWidthBlockTitlte();

            $(window).resize(function() {
                autoWidthBlockTitlte();
            });
        }

        function autoWidthBlockTitlte() {
            var mainDivs = $(".locations__wrapper .l-bottom .l-wrapper .h-left__title");
            for (var i = 0; i < mainDivs.length; ++i) {
                $(mainDivs[i]).css('width', '');
            }
            var maxWidth = 0;
            for (var i = 0; i < mainDivs.length; ++i) {
                if (maxWidth < $(mainDivs[i]).width()) {
                    maxWidth = $(mainDivs[i]).width();
                }
            }
            for (var i = 0; i < mainDivs.length; ++i) {
                $(mainDivs[i]).width(maxWidth);
            }
        }
    },
    'paginationHeight': function () {

        if($('.c-slider').length) {
            autoHeightPagination();

            $(window).resize(function() {
                autoHeightPagination();
            });
        }

        function autoHeightPagination() {

            var mainDivs = $('.swiper-pagination__lines');
            var maxHeight = 0;

            for (var i = 0; i < mainDivs.length; ++i) {
                if (maxHeight < $(mainDivs[i]).height()) {
                    maxHeight = $(mainDivs[i]).height();
                }
            }

            for (var i = 0; i < mainDivs.length; ++i) {
                $(mainDivs[i]).height(maxHeight);
            }
        }
    },
    'input-slider': function ($thisModule) {
        var sliderBlock = $thisModule.find('.calculator-small-slider__slider'),
            min = sliderBlock.data('min'),
            max = sliderBlock.data('max'),
            input = $thisModule.find('.is-input'),
            inputDefValue = input.val();

        sliderBlock.slider({
            range: "min",
            min: min,
            max: max,
            value: inputDefValue,
            slide: function(event, ui) {
                input.val(ui.value)[0].dispatchEvent(new Event('input'));
            }
        });

        input.on('input',function(){
            sliderBlock.slider("value", $(this).val());
        });

    },
    'stickers-js': function () {

        setTimeout(function () {

            setStickerImg();
        }, 100)

        $(window).resize(function() {
            setStickerImg();
        });

        function setStickerImg() {
            var mainDivs = $(".page-events-detail__stickers .stickers__wrapper .stickers .item__sticker .sticker__img");
            var maxHeight = 0;
            for (var i = 0; i < mainDivs.length; ++i) {
                if (maxHeight < $(mainDivs[i]).height()) {
                    maxHeight = $(mainDivs[i]).height();
                }
            }
            for (var i = 0; i < mainDivs.length; ++i) {
                $(mainDivs[i]).height(maxHeight);
            }
        }
    },
    'initDatePicker': function () {
        if($('.is-datepicker-js').length) {

            var dateParam = getAllUrlParams().date;

            if(dateParam) {
                var dateParam = new Date(dateParam * 1000);
            }

            if(dateParam) {

                var datepicker = new Datepicker('#date_input', {
                    min: (function(){
                        var date = new Date();
                        date.setDate(date.getDate() - 1);
                        return date;
                    })()
                });

            } else {

                var datepicker = new Datepicker('#date_input', {
                    min: (function(){
                        var date = new Date();
                        date.setDate(date.getDate() - 1);
                        return date;
                    })(),
                    multiple: true,
                    onChange: (function () {
                        if($('#date_input')) {

                            $('#date_input').attr('data-dates-selected', $('#date_input').val())

                            if(this._selected.length >= 2) {

                                $('#date_input').val(this._selected.length + ' days')
                            }
                        }
                    })
                });

            }


            $( "#date_input" ).datepicker( "option", "dateFormat", 'yy-mm-dd' );

            if(dateParam) {
                $(".is-datepicker-js").val(("0"+(dateParam.getUTCDate())).slice(-2)+"/"+('0'+(dateParam.getUTCMonth()+1)).slice(-2)+"/"+dateParam.getUTCFullYear());
            } else {

            }

            setInterval(function () {
                $('.is-datepicker-js').click();
            }, 100)
        }

        function getAllUrlParams(url) {

            var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

            var obj = {};

            if (queryString) {
                queryString = queryString.split('#')[0];
                var arr = queryString.split('&');
                for (var i=0; i<arr.length; i++) {
                    var a = arr[i].split('=');
                    var paramNum = undefined;
                    var paramName = a[0].replace(/\[\d*\]/, function(v) {
                        paramNum = v.slice(1,-1);
                        return '';
                    });
                    var paramValue = typeof(a[1])==='undefined' ? true : a[1];
                    paramName = paramName.toLowerCase();
                    paramValue = paramValue.toLowerCase();
                    if (obj[paramName]) {
                        if (typeof obj[paramName] === 'string') {
                            obj[paramName] = [obj[paramName]];
                        }
                        if (typeof paramNum === 'undefined') {
                            obj[paramName].push(paramValue);
                        }
                        else {
                            obj[paramName][paramNum] = paramValue;
                        }
                    }
                    else {
                        obj[paramName] = paramValue;
                    }
                }
            }
            return obj;
        }

    },
    'setHeightCasesEvent': function () {

        if($('.page-events-detail__new .page-events-detail__cases').length > 0) {

            $(window).resize(function() {
                setHeightImgCases();
                setHeightContentCases();
            });

            setHeightImgCases();
            setHeightContentCases();

            function setHeightImgCases() {

                var mainDivs = $(".page-events-detail .page-events-detail__cases .section__content .case .top-content__case");
                var maxHeight = 0;
                var screensize = $(window).width();

                for (var i = 0; i < mainDivs.length; ++i) {
                    $(mainDivs[i]).css('height', '');
                }

                for (var i = 0; i < mainDivs.length; ++i) {

                    if (maxHeight < $(mainDivs[i]).height()) {

                        maxHeight = $(mainDivs[i]).height();
                    }
                }

                for (var i = 0; i < mainDivs.length; ++i) {
                    $(mainDivs[i]).height(maxHeight);
                }
            }

            function setHeightContentCases() {

                var mainDivs = $(".page-events-detail .page-events-detail__cases .section__content .case .bot-content__case");
                var maxHeight = 0;
                var screensize = $(window).width();

                for (var i = 0; i < mainDivs.length; ++i) {
                    $(mainDivs[i]).css('height', '');
                }

                for (var i = 0; i < mainDivs.length; ++i) {

                    if (maxHeight < $(mainDivs[i]).height()) {

                        maxHeight = $(mainDivs[i]).height();
                    }
                }

                for (var i = 0; i < mainDivs.length; ++i) {
                    $(mainDivs[i]).height(maxHeight);
                }
            }
        }
    },
    'calculator': function () {
        var selects = $('select');
        selects.select2({
            minimumResultsForSearch: Infinity,
        });
    },
    'lazyLoadVideosAfterLoadPage': function () {

        //lazyLoadSrc
        var arVideos = $('video.video__lazy--ready');

        if(arVideos) {
            $.each(arVideos , function(index, el) {
                var source = $(el).find('source');
                var lazyLoadSrc = source.attr('data-src');

                if(source && lazyLoadSrc) {
                    source.removeAttr('data-src');
                    source.attr('src', lazyLoadSrc);
                    $(el)[0].load()
                }
            });
        }
    },
    'lazyLoadImagesAfterLoadPage': function () {

        //lazyLoadSrc
        var arImages = $('img.img__lazy--ready');

        if(arImages) {
            $.each(arImages , function(index, el) {
                element = $(el);
                var lazyLoadSrc = element.attr('data-src-ready');

                if(lazyLoadSrc) {
                    element.removeAttr('data-src-ready');
                    element.attr('src', lazyLoadSrc);
                }
            });
        }
    },
    'lazyLoadPictureImagesAfterLoadPage': function () {
        //lazyLoadSrc
        var arPictureSources = $('picture.picture__lazy--ready source');

        if(arPictureSources) {
            $.each(arPictureSources , function(index, el) {
                element = $(el);
                var lazyLoadSrc = element.attr('data-srcset-ready');

                if(lazyLoadSrc) {
                    element.removeAttr('data-srcset-ready');
                    element.attr('srcset', lazyLoadSrc);

                    element[0].addEventListener('load', function(e) {
                        $(window).trigger('resize');
                    });
                }
            });
        }

        var arPictureImg = $('picture.picture__lazy--ready img');

        if(arPictureImg) {
            $.each(arPictureImg , function(index, el) {
                element = $(el);
                var lazyLoadSrc = element.attr('data-src-ready');

                if(lazyLoadSrc) {
                    element.removeAttr('data-src-ready');
                    element.attr('src', lazyLoadSrc);

                    element[0].addEventListener('load', function(e) {
                        $(window).trigger('resize');
                    });
                }
            });
        }
    },
    'lazyLoadPosterVideoAfterLoadPage': function () {

        //lazyLoadSrc
        var arVideos = $('video');

        if(arVideos) {
            $.each(arVideos , function(index, el) {
                var element = $(el);
                if(element.attr('data-src-poster')) {
                    var lazyLoadSrc = element.attr('data-src-poster');

                    if(lazyLoadSrc) {
                        element.removeAttr('data-src-poster');
                        element.attr('poster', lazyLoadSrc);
                    }
                }
            });
        }
    },
    'lazyLoadImagesBackgroundStyle': function () {

        //lazyLoadSrc
        var arPictureSources = $('.lazy_background__img--ready');

        if(arPictureSources) {
            $.each(arPictureSources , function(index, el) {

                element = $(el);

                var lazyLoadSrc = element.attr('data-src-ready');

                if(lazyLoadSrc) {
                    element.removeAttr('data-src-ready');
                    element.css({
                        'background-image': 'url' + '(' + lazyLoadSrc + ')'
                    });
                }
            });
        }
    }
};

var pageApp = {
    'init': function () {
        var curApp = $('#app').attr('data-app');
        if (pageApp[curApp]) {
            pageApp[curApp]();
        }
    },
    'page-index': function () {
        console.log('index-here');
    }
};


$(document).ready(function () {
    moduleApp.init();

    //pageApp.init();

    $(".show_more_game").on('click', function () {

        var text = $(this).parent().prev();
        var buttonWrapper = $(this).parent();

        if($(this).parent().hasClass("active")){

            text.hide(300);
            $(this).find("span").text("Show more");
            buttonWrapper.removeClass("active");

        }else{

            text.show(300);
            $(this).find("span").text("Show less");
            buttonWrapper.addClass("active");

        }

        return false;

    })

});

/*var locationThumbs2 = new Swiper('.location-thumbs2', {
    spaceBetween: 10,
    slidesPerView: 3,
    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
});
var locationTop2 = new Swiper('.location-top2', {
    spaceBetween: 10,
    thumbs: {
        swiper: locationThumbs2
    }
});
var locationThumbs3 = new Swiper('.location-thumbs3', {
    spaceBetween: 10,
    slidesPerView: 3,
    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
});
var locationTop3 = new Swiper('.location-top3', {
    spaceBetween: 10,
    thumbs: {
        swiper: locationThumbs3
    }
});
*/

